import { action, makeObservable, observable } from "mobx";
import { HTTPservice } from "../services/HTTPservice";
import jwtDecode from "jwt-decode";
export class UserStore {
  constructor() {
    makeObservable(this, {
      isAdmin: observable,
      login: action,
      setIsAdmin: action,
    });
  }

  isAdmin: boolean = false;
  public async login(login: string, password: string) {
    const body = { login, password };
    const res = await HTTPservice.post<{ token: string }>("user/login", body);
    // if (res === 401) throw new Error();
    // return 401;
    const { token } = res;
    localStorage.setItem("token", token);
    if (token?.split(".").length === 3) {
      this.setIsAdmin(
        jwtDecode<{ role: string; login: string }>(token).role === "ADMIN"
      );
    } else this.setIsAdmin(false);
  }

  public async auth() {
    const { token } = await HTTPservice.get<{ token: string }>(
      "user/auth",
      true
    );
    localStorage.setItem("token", token);
    if (token?.split(".").length === 3) {
      this.setIsAdmin(
        jwtDecode<{ role: string; login: string }>(token).role === "ADMIN"
      );
    } else this.setIsAdmin(false);
  }

  public async setIsAdmin(value: boolean) {
    this.isAdmin = value;
  }
}
