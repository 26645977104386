import { stores } from "../stores/rootStore";
class HTTPError extends Error {}

export class HTTPservice {
  static baseBackURL = process.env.REACT_APP_BACK_ENV + "api/";

  static getHeaderAuth() {
    return {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
  }
  static async get<T = never>(
    pathRequest: string,
    isNeedAuth: boolean = false
  ): Promise<T> {
    const headers = isNeedAuth ? this.getHeaderAuth() : undefined;
    const response = await fetch(this.baseBackURL + pathRequest, {
      headers,
    });

    if (!response.ok) {
      if (response.status === 401) {
        const { userStore } = stores;
        userStore.setIsAdmin(false);
        throw new HTTPError(`Auth fail: ${response.statusText}`);
      }
      if (response.status === 502) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        return this.get(pathRequest, isNeedAuth);
      }
      throw new HTTPError(`Fetch error: ${response.statusText}`);
    }

    const json: T = await response.json();

    return json;
  }

  static async post<TAnswer = never>(
    pathRequest: string,
    body: any,
    isNeedAuth: boolean = false
  ): Promise<TAnswer> {
    const isPostPhotos = pathRequest === "photos";
    const headers: {
      Authorization?: string;
      "Content-Type"?: string;
    } = {};

    if (isNeedAuth) headers.Authorization = this.getHeaderAuth().Authorization;
    if (!isPostPhotos)
      headers["Content-Type"] = "application/json;charset=utf-8";

    const bodyForSend = !isPostPhotos ? JSON.stringify(body) : body;
    const response = await fetch(this.baseBackURL + pathRequest, {
      method: "POST",
      headers,
      body: bodyForSend,
    });

    if (!response.ok) {
      if (response.status === 401) {
        const { userStore } = stores;
        userStore.setIsAdmin(false);
        throw new HTTPError(`Auth fail: ${response.statusText}`);
        // return 401;
      }
      throw new HTTPError(`Fetch error: ${response.statusText}`);
    }

    const json: TAnswer = await response.json();

    return json;
  }
  static async put<TAnswer = never>(
    pathRequest: string,
    body: any,
    isNeedAuth: boolean = false
  ): Promise<TAnswer> {
    const headers: {
      Authorization?: string;
      "Content-Type": string;
    } = {
      "Content-Type": "application/json;charset=utf-8",
    };

    if (isNeedAuth) headers.Authorization = this.getHeaderAuth().Authorization;

    const bodyForSend = JSON.stringify(body);
    const response = await fetch(this.baseBackURL + pathRequest, {
      method: "PUT",
      headers,
      body: bodyForSend,
    });

    if (!response.ok) {
      if (response.status === 401) {
        const { userStore } = stores;
        userStore.setIsAdmin(false);
        throw new HTTPError(`Auth fail: ${response.statusText}`);
        // return 401;
      }
      throw new HTTPError(`Fetch error: ${response.statusText}`);
    }

    const json: TAnswer = await response.json();

    return json;
  }

  static async patch<TAnswer = never>(
    pathRequest: string,
    body: any,
    isNeedAuth: boolean = false
  ): Promise<TAnswer> {
    const headers: {
      Authorization?: string;
      "Content-Type": string;
    } = {
      "Content-Type": "application/json;charset=utf-8",
    };

    if (isNeedAuth) headers.Authorization = this.getHeaderAuth().Authorization;

    const bodyForSend = JSON.stringify(body);
    const response = await fetch(this.baseBackURL + pathRequest, {
      method: "PATCH",
      headers,
      body: bodyForSend,
    });

    if (!response.ok) {
      if (response.status === 401) {
        const { userStore } = stores;
        userStore.setIsAdmin(false);
        throw new HTTPError(`Auth fail: ${response.statusText}`);
        // return 401;
      }
      throw new HTTPError(`Fetch error: ${response.statusText}`);
    }

    const json: TAnswer = await response.json();

    return json;
  }

  static async delete(
    pathRequest: string,
    isNeedAuth: boolean = false
  ): Promise<number> {
    const headers: {
      Authorization?: string;
      "Content-Type": string;
    } = {
      "Content-Type": "application/json;charset=utf-8",
    };

    if (isNeedAuth) headers.Authorization = this.getHeaderAuth().Authorization;

    const response = await fetch(this.baseBackURL + pathRequest, {
      method: "DELETE",
      headers,
    });

    if (!response.ok) {
      if (response.status === 401) {
        const { userStore } = stores;
        userStore.setIsAdmin(false);
        // return 401;
        throw new HTTPError(`Auth fail: ${response.statusText}`);
      }
      throw new HTTPError(`Fetch error: ${response.statusText}`);
    }
    //возвращает кол-во удаленных объектов
    const json: number = await response.json();

    return json;
  }
}
