import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect } from "react";
import cross from "../img/cross.svg";

import "./style.css";

export const PopUpRules: FC<{
  hidePopUp: () => void;
}> = observer(({ hidePopUp }) => {
  const escFunction = useCallback((evt: any) => {
    if (evt.key === "Escape") {
      hidePopUp();
    }
  }, []); // eslint-disable-line
  useEffect(() => {
    document.addEventListener("keydown", (evt) => escFunction(evt), false);

    return () => {
      document.removeEventListener("keydown", (evt) => escFunction(evt), false);
    };
  }, [escFunction]);

  return (
    <div className="newOrder_popUp" onClick={hidePopUp}>
      <div className="no_popUp" onClick={(e) => e.stopPropagation()}>
        <div className="nop_title">
          Соглашение на обработку персональных данных
        </div>
        <div className="nop_content">
          <p>
            1.1 ООО «РОЗА-ЛЮКС» (далее по тексту – Оператор) ставит соблюдение
            прав и свобод граждан одним из важнейших условий осуществления своей
            деятельности.
          </p>
          <p>
            1.2 Политика Оператора в отношении обработки персональных данных
            (далее по тексту — Политика) применяется ко всей информации, которую
            Оператор может получить о посетителях веб-сайта rozalux-bratsk.ru.
            Персональные данные обрабатывается в соответствии с ФЗ «О
            персональных данных» № 152-ФЗ.
          </p>
          <p>
            2.1 Оператор может обрабатывать следующие персональные данные:
            фамилия, имя, отчество Пользователя, контактный телефон, почтовый
            адрес доставки заказов, платёжные реквизиты
          </p>
          <p>
            2.2 Также на сайте происходит сбор и обработка обезличенных данных о
            посетителях (в т.ч. файлов «cookie») с помощью сервисов
            интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
          </p>
          <p>
            3.1 Цель обработки данных -уточнение деталей заказа, оказание
            услуги.
            {/* Также Оператор имеет право направлять Пользователю
            уведомления о новых продуктах и услугах, специальных предложениях и
            различных событиях. Пользователь всегда может отказаться от
            получения информационных сообщений, направив Оператору письмо на
            адрес vashapochta@yandex.ru */}
          </p>
          <p>
            3.2 Обезличенные данные Пользователей, собираемые с помощью сервисов
            интернет-статистики, служат для улучшения качества сайта и его
            содержания.
          </p>
          <p>
            4.1 Оператор обрабатывает персональные данные Пользователя только в
            случае их отправки Пользователем через формы, расположенные на сайте
            rozalux-bratsk.ru. Отправляя свои персональные данные Оператору,
            Пользователь выражает свое согласие с данной Политикой.
          </p>
          <p>
            5.1 Оператор обеспечивает сохранность персональных данных и
            принимает все возможные меры, исключающие доступ к персональным
            данным неуполномоченных лиц.
          </p>
          <p>
            5.2 Персональные данные Пользователя никогда, ни при каких условиях
            не будут переданы третьим лицам, за исключением случаев, связанных с
            исполнением действующего законодательства.
          </p>
          <p>
            5.3 Срок обработки персональных данных является неограниченным.
            Пользователь может в любой момент отозвать свое согласие на
            обработку персональных данных, направив Оператору уведомление с
            помощью электронной почты на электронный адрес Оператора
            ordersroselux@gmail.com, с пометкой «Отзыв согласия на обработку
            персональных данных».
          </p>
          <p>
            6.1 Гарантирую, что представленная мной информация является полной,
            точной и достоверной, а также что при представлении информации не
            нарушаются действующее законодательство Российской Федерации,
            законные права и интересы третьих лиц. Вся представленная информация
            заполнена мною в отношении себя лично.
          </p>
        </div>
        <img
          className="nop_close"
          src={cross}
          onClick={hidePopUp}
          alt="Закрыть"
          title="Закрыть"
        />
      </div>
    </div>
  );
});
