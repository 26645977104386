import { action, makeObservable, observable, runInAction } from "mobx";
import { HTTPservice } from "../services/HTTPservice";
import { TDaDataAddress } from "./types";
export class AddressStore {
  constructor() {
    makeObservable(this, {
      addressSuggetions: observable,
      fetchAddress: action,
      address: observable,
      setAddress: action,
    });
  }

  addressSuggetions: TDaDataAddress[] = [];
  address: TDaDataAddress | undefined;

  setAddress(newAddress?: TDaDataAddress) {
    this.address = newAddress;
  }
  public async fetchAddress(query: string) {
    const res = await HTTPservice.post<{ suggestions: TDaDataAddress[] }>(
      "dadata",
      { query },
      true
    );
    runInAction(() => {
      this.addressSuggetions = res.suggestions ?? [];
    });
    return res;
  }
}
