import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ImageGallery from "react-image-gallery";
import rubsvg from "../img/rub.svg";

import { stores } from "../stores/rootStore";
import "./style.css";
import { TProductDefination, TProductInOrder } from "../stores/types";
import { getPhotoForGallery } from "../utils/galleryUtils";
import { basketSVG } from "../img/basketImg";
import { YMaddProductToBasket, YMopenProductCard } from "../utils/YMecomerce";
import { Slider } from "../components/slider/Slider";
import { runInAction } from "mobx";
import { svgPencil } from "../img/tools/pencil";
import {
  renderLeftNav,
  renderRightNav,
} from "../components/imageGallery/imageGalleryComponents";
export const Product = observer(() => {
  const {
    productStore,
    helpUIStore,
    orderStore,
    userStore,
    settingStore: {
      settings: { is_sales_close: isSalesClose },
    },
  } = stores;

  const { id: idProduct } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const PDidFromQueryParam = searchParams.get("pd");

  const [activPD, setActivPD] = useState<TProductDefination>();
  const setPD = (PD?: TProductDefination) => {
    if (!PD) return;
    setActivPD(PD);
    setSearchParams({ pd: PD.id.toString() });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!idProduct) return;

    if (productStore.fetchingProduct === +idProduct) return;
    helpUIStore.setTitle(" ");

    const getProduct = async () => {
      if (productStore.oneProduct?.id !== +idProduct) {
        runInAction(() => (productStore.oneProduct = undefined));
        await productStore.fetchOneProduct(idProduct);
      }

      if (!productStore.oneProduct) return;
      const { Category, name, id, ProductDefinations } =
        productStore.oneProduct;

      if (PDidFromQueryParam) {
        setActivPD(
          ProductDefinations.find((v) => v.id === +PDidFromQueryParam) ||
            ProductDefinations[0]
        );
      } else {
        setPD(ProductDefinations[0]);
      }

      YMopenProductCard({
        id: id.toString(),
        name,
        category: Category.name,
        ...(ProductDefinations.length > 1 && {
          variant: activPD?.name_variant,
        }),
      });

      helpUIStore.setTitle(name ?? "");
    };

    getProduct();

    return () => {
      helpUIStore.setHistory([helpUIStore.history[0], "product"]);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      PDidFromQueryParam &&
      activPD?.id &&
      activPD.id !== +PDidFromQueryParam
    ) {
      setActivPD(
        productStore.oneProduct?.ProductDefinations.find(
          (v) => v.id === +PDidFromQueryParam
        )
      );
    }
  }, [PDidFromQueryParam]); // eslint-disable-line

  //TODO svg animate rose when loading
  if (productStore.isLoading) return <Slider isShow={productStore.isLoading} />;

  if (productStore.fetchingProduct) return <></>;
  if (!productStore.oneProduct) return <>Такого продукта нет :(</>;
  if (!activPD) return <></>;
  const { ProductDefinations, name, Category, is_archive } =
    productStore.oneProduct!;
  const {
    components,
    id: idPD,
    name_variant,
    photos,
    price,
    description = "",
  } = activPD!;

  const addInBasket = () => {
    const isManyPD = ProductDefinations.length > 1;

    const productToBasket: TProductInOrder = {
      idProduct: +idProduct!,
      idPD,
      amount: 1,
      components: {},
      description,
      name: isManyPD ? `${name} ${name_variant}` : name,
      photos,
      price,
      total_price: price,
      refOnProduct: `${idProduct}?pd=${idPD}`,
      prodName: name,
      ...(isManyPD && {
        PDName: name_variant,
      }),
    };
    orderStore.setProductsInOrder([
      ...orderStore.productsInOrder,
      productToBasket,
    ]);
    YMaddProductToBasket({
      id: idPD.toString(),
      category: Category.name,
      name,
      price,
      ...(isManyPD && {
        variant: name_variant,
      }),
    });
  };
  const basketBtn = orderStore.productsInOrder
    .map((v) => v.idPD)
    .includes(activPD?.id ?? 0) ? (
    <Link to={"/newOrder"} title="Перейти в корзину">
      <div className="product_basket_btn item_basket rose_btn item_basket_active">
        <div className="item_basket_img">{basketSVG}</div>
        <span>В корзине</span>
      </div>
    </Link>
  ) : (
    <div
      title="Добавить в корзину"
      className="product_basket_btn item_basket rose_btn"
      onClick={addInBasket}
    >
      {basketSVG}
      <span>В корзину</span>
    </div>
  );

  const choosePd = (index: number) => setPD(ProductDefinations[index]);

  const productDefinationsBtns = ProductDefinations.map(
    ({ id, name_variant }, i) => {
      let clazz =
        idPD === id
          ? "productClient_btn productClient_btn_active"
          : "productClient_btn productClient_btn_passive";
      if (i === 0) clazz += " productClient_btn_first";
      return (
        <div className={clazz} key={id} onClick={() => choosePd(i)}>
          <span>{name_variant}</span>
        </div>
      );
    }
  );
  const componentList = components
    .filter((v) => v.is_show_to_client || userStore.isAdmin)
    .map(({ name, amount, is_show_to_client }) => {
      return (
        <li key={name} style={is_show_to_client ? {} : { color: "#00000070" }}>
          {name}: {amount} шт
        </li>
      );
    });
  return (
    <>
      <div className="productClient_linkBack">
        <Link to={`/catalog/${Category.id}`}>{Category.name}</Link>
        <span>{` › ${name}`}</span>
      </div>
      <div className="productClient_wrapper">
        <div className="productClient_photos">
          <ImageGallery
            items={getPhotoForGallery(photos, true, `${name} ${name_variant}`)}
            showThumbnails={true}
            showFullscreenButton={true}
            showPlayButton={false}
            showBullets={(photos.length ?? 0) > 1}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
          />
        </div>
        <div className="productClient_content">
          {productDefinationsBtns.length > 1 && (
            <div className="productClient_options">
              {productDefinationsBtns}
            </div>
          )}
          <div className="prodClient_price_box">
            <div className="productClient_price">{price}</div>
            <img src={rubsvg} alt="" />
            {userStore.isAdmin && (
              <div
                className="svgTool svgEdit productEditIcon"
                title="Редактировать продукт"
                onClick={() =>
                  navigate(`../../admin/products/edit/${idProduct}`)
                }
              >
                {svgPencil}
              </div>
            )}
          </div>
          {!is_archive && !isSalesClose && basketBtn}
          <div className="productClient_descriptions">{description}</div>
          {!!componentList.length && (
            <div className="productClient_components">
              Состав:
              <ul>{componentList}</ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
});
