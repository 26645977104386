import { useState } from "react";

export type TuseFieldState<T> = {
  isValid: boolean;
  value: T;
  isObserve: boolean;
  hint: string;
};
export type TuseFieldSetState<T> = React.Dispatch<
  React.SetStateAction<TuseFieldState<T>>
>;

type TuseFieldReturn<T> = [TuseFieldState<T>, TuseFieldSetState<T>, () => void];

export const useField = <T>(initState: T): TuseFieldReturn<T> => {
  const [fieldInp, setFieldInp] = useState({
    isValid: true,
    value: initState,
    isObserve: false,
    hint: "",
  });
  const resetState = () => {
    setFieldInp({
      isValid: true,
      value: initState,
      isObserve: false,
      hint: "",
    });
  };

  return [fieldInp, setFieldInp, resetState];
};

export const isValid = (
  fieldsWithSettersForValidate: TuseFieldReturn<any>[],
  arrIsValid: boolean[] = []
) => {
  const fieldsForValidate = fieldsWithSettersForValidate.map((v) => v[0]);
  const settersFields = fieldsWithSettersForValidate.map((v) => v[1]);
  //при первом вызове функции переключаем в режим слежения, чтоб компонент мог менять стили при вводе
  if (!fieldsForValidate[0].isObserve) {
    settersFields.forEach((setField) => {
      setField((field: any) => ({ ...field, isObserve: true }));
    });
  }
  return (
    !fieldsForValidate.find((field) => field.isValid === false) &&
    !arrIsValid.includes(false)
  );
};
