import { action, makeObservable, observable, runInAction } from "mobx";
import { HTTPservice } from "../services/HTTPservice";
import { TCategory } from "./types";
export class CategoryStore {
  constructor() {
    makeObservable(this, {
      categories: observable,
      fetchCategories: action,
    });
  }

  categories: TCategory[] = [];

  public async fetchCategories(withEmpty = false) {
    const res = await HTTPservice.get<TCategory[]>(
      withEmpty ? "categories?all=true" : "categories"
    );
    runInAction(() => {
      this.categories = res;
    });

    return res;
  }

  public async addCategory(body: Omit<TCategory, "id">) {
    const res = await HTTPservice.post<TCategory>("categories", body, true);
    // if (res === 401) throw new Error("not auth");
    // else {
    this.fetchCategories();
    return res;
    // }
  }

  public async updateCategory(body: TCategory) {
    const res = await HTTPservice.put<TCategory>(
      `categories/${body.id}`,
      body,
      true
    );
    // if (res === 401) throw new Error("not auth");
    // else {
    this.fetchCategories();
    return res;
    // }
  }

  public async deleteCategory(id: number) {
    const res = await HTTPservice.delete(`categories/${id}`, true);
    if (res === 401) throw new Error("not auth");
    else {
      this.fetchCategories();
      return res;
    }
  }
}
