import "./style.css";
import sliderImg from "../../img/slider.svg";
import { FC } from "react";
export const Slider: FC<{ isShow: boolean }> = ({ isShow }) => {
  //   if (!isShow) return <></>;
  const clazz = isShow ? "slider_wrapper" : "slider_hide";
  return (
    <div className={clazz}>
      <img src={sliderImg} alt="loading" />
    </div>
  );
};
