import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { stores } from "../../stores/rootStore";
import { ProductItem } from "../productItem/ProductItem";
import { svgDown } from "../../img/down";
import { svgOK } from "../../img/tools/OK";

import "./style.css";
import { scrollHandler } from "../../utils/scrollHandler";
import { TProductsSortON } from "../../stores/ProductStore";
import { Input } from "../../components/inputs/inputs";
import { Slider } from "../../components/slider/Slider";
import { svgCloseWhite } from "../../img/tools/closeWhite";
export const ProductList: FC<{ isSearchPage: boolean }> = observer(
  ({ isSearchPage }) => {
    const { id: categoryId } = useParams();
    const { productStore, categoryStore, helpUIStore } = stores;
    const [alreadyBeenFetched, setAlreadyBeenFetched] = useState(false);
    useEffect(() => {
      setAlreadyBeenFetched(false);
      if (!categoryId) return;
      productStore
        .setOptionsAndFetchAll(
          {
            categoryIdForFetch:
              categoryId === "all" ? "setUndefined" : categoryId,
          },
          helpUIStore.history[0] !== "catalog"
        )
        .then(({ items }) => {
          setAlreadyBeenFetched(true);

          if (categoryId === "all") {
            helpUIStore.setTitle("Все товары");
          } else if (isSearchPage) {
            return;
          } else {
            helpUIStore.setTitle(items[0].Category.name);
          }
        });

      helpUIStore.setHistory(["catalog"]);
    }, [categoryId]); // eslint-disable-line

    useEffect(() => {
      if (!isSearchPage) return;
      stores.helpUIStore.setTitle("Поиск");

      productStore.setOptionsAndFetchAll(
        {},
        helpUIStore.history[0] !== "searchPage"
      );
      setSearchVal(productStore.searchOn ?? "");
      helpUIStore.setHistory(["searchPage"]);
    }, [isSearchPage]); // eslint-disable-line

    const onScroll = (e: any) => {
      if (
        stores.productStore.products.length !== stores.productStore.count &&
        stores.productStore.offset < stores.productStore.count
      )
        scrollHandler(e, stores.productStore.getMoreProducts);
    };
    useEffect(() => {
      document.addEventListener("scroll", onScroll);
      return () => document.removeEventListener("scroll", onScroll);
    }, []);

    const [isShowDDSort, setIsShowDDSort] = useState(false);
    const [isShowDDPrice, setIsShowDDPrice] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    useEffect(() => {
      // if (searchVal.length < 2) return;
      const newSearchOn = searchVal.length >= 2 ? searchVal : "setUndefined";
      const doFetch = () => {
        if (!productStore.searchOn && newSearchOn === "setUndefined") return;
        productStore.setOptionsAndFetchAll({
          categoryIdForFetch: "setUndefined",
          searchOn: newSearchOn,
        });
      };

      const timer = setTimeout(doFetch, 400);
      return () => clearTimeout(timer);
    }, [searchVal]); // eslint-disable-line
    const [valuesInputsPrice, setValuesInputsPrice] = useState<
      [string, string]
    >(["", ""]);

    const navBarList = categoryStore.categories.map(({ id, name }) => {
      const link = `/catalog/${id}`;
      let prepareName: any = name;
      if (prepareName.length > 18) {
        for (let i = 17; i < prepareName.length - 1; i++) {
          if (prepareName[i] === " ") {
            prepareName = (
              <span>
                {prepareName.substring(0, i)}
                <br />
                {prepareName.substring(i + 1, prepareName.length)}
              </span>
            );
            break;
          }
        }
      }
      return (
        <li key={id}>
          <NavLink to={link}>{prepareName}</NavLink>
        </li>
      );
    });
    const setSort = (sortOn: TProductsSortON) => {
      productStore.setOptionsAndFetchAll({ sortOn });
      setIsShowDDSort(false);
    };
    const setPrice = (priceOn: string | null) => {
      !priceOn || priceOn === productStore.priceOn
        ? productStore.setOptionsAndFetchAll({ priceOn: "setUndefined" })
        : productStore.setOptionsAndFetchAll({ priceOn });
      setIsShowDDPrice(false);
    };

    const priceOptions: [string, string][] = useMemo(() => {
      if (categoryId === "all" || isSearchPage) {
        return [
          ["До 1000 руб", "0between1000"],
          ["До 2000 руб", "0between2000"],
          ["До 4000 руб", "0between4000"],
          ["_", "inputs"],
        ];
      }
      return [
        ["До 3000 руб", "0between3000"],
        ["До 4000 руб", "0between4000"],
        ["До 6000 руб", "0between6000"],
        ["_", "inputs"],
      ];
    }, [categoryId, isSearchPage]);

    const sortOptions: [string, TProductsSortON][] = [
      ["Сначала новинки", "id_DESC"],
      ["Сначала дешевле", "price_ASC"],
      ["Сначала дороже", "price_DESC"],
      ['Сначала на "А"', "name_ASC"],
      ['Сначала на "Я"', "name_DESC"],
    ];
    const DDoptionsSort = sortOptions.map(([title, value]) => {
      const clazz =
        productStore.sortOn === value
          ? "dd_option dd_option_active"
          : "dd_option";
      return (
        <div key={value} className={clazz} onClick={() => setSort(value)}>
          {title}
        </div>
      );
    });
    const DDoptionsPrice = priceOptions.map(([title, value], i) => {
      const clazz =
        productStore.priceOn === value
          ? "dd_option dd_option_active"
          : "dd_option";

      if (value === "inputs") {
        const classInputs =
          productStore.priceOn &&
          !priceOptions.map((v) => v[1]).includes(productStore.priceOn)
            ? "dd_option dd_option_active dd_options_custom_price"
            : "dd_option dd_options_custom_price";
        return (
          <div key={value} className={classInputs}>
            от{" "}
            <input
              type="text"
              inputMode="numeric"
              className="dd_option_input"
              value={valuesInputsPrice[0]}
              onChange={(e) => {
                if (isNaN(+e.target.value)) return;
                setValuesInputsPrice([e.target.value, valuesInputsPrice[1]]);
              }}
            />{" "}
            до{" "}
            <input
              type="text"
              inputMode="numeric"
              className="dd_option_input"
              value={valuesInputsPrice[1]}
              onChange={(e) => {
                if (isNaN(+e.target.value)) return;
                setValuesInputsPrice([valuesInputsPrice[0], e.target.value]);
              }}
            />
            <div
              onClick={() => {
                const [min, max] = valuesInputsPrice;
                if (min === max) return;
                const minVal = min === "" ? 0 : +min;
                const maxVal = max === "" ? 99999 : +max;
                setPrice(minVal + "between" + maxVal);
              }}
              className="dd_option_input_ok"
              title="Применить"
            >
              {svgOK}
            </div>
          </div>
        );
      }
      return (
        <div key={value} className={clazz} onClick={() => setPrice(value)}>
          {title}
        </div>
      );
    });

    const classDDSort = isShowDDSort
      ? "rose_dropdown-options"
      : "rose_dropdown-options hide_dd";
    const classDDPrice = isShowDDPrice
      ? "rose_dropdown-options"
      : "rose_dropdown-options hide_dd";

    const list = productStore.products?.map((product) => {
      return (
        <div
          key={product?.ProductDefinations[0]?.id}
          className="productList_item"
        >
          <ProductItem product={product} />
        </div>
      );
    });

    const titleBtnCost =
      document.body.clientWidth > 350 ? "Выбрать стоимость" : "Стоимость";

    const costFilterString = useMemo(() => {
      if (!productStore.priceOn) return "";
      const prices = productStore.priceOn.split("between");
      return `от ${prices[0]} до ${prices[1]} руб`;
    }, [productStore.priceOn]);

    return (
      <div className="catalog_wrapper">
        <nav className="catalog_navBar">
          <ul>{navBarList}</ul>
        </nav>
        <div className="catalog_content">
          <div className="catalog_filterPanel">
            <div className="rose_dropdown">
              <div
                className="rose_btn filterPanel_btn"
                onClick={() => {
                  if (isShowDDPrice) setIsShowDDPrice(false);
                  setIsShowDDSort((v) => !v);
                }}
              >
                <span>Сортировать</span>
                <div className="filterPanel_down">{svgDown}</div>
              </div>
              <div className={classDDSort}>{DDoptionsSort}</div>
            </div>
            <div className="rose_dropdown">
              <div
                // className={classDDBtnPrice}
                className="rose_btn filterPanel_btn"
                onClick={() => {
                  if (isShowDDSort) setIsShowDDSort(false);
                  setIsShowDDPrice((v) => !v);
                }}
              >
                <span>{titleBtnCost}</span>
                <div className="filterPanel_down">{svgDown}</div>
              </div>
              <div style={{ right: "0" }} className={classDDPrice}>
                {DDoptionsPrice}
              </div>
            </div>

            {!!productStore.priceOn && (
              <div className="filterPanel_priceActive ">
                <div className="">{costFilterString}</div>
                <div
                  className="svgTool svgCancel"
                  title="Убрать фильтр"
                  id="filterPanel_cancel"
                  onClick={() => setPrice(null)}
                >
                  {svgCloseWhite}
                </div>
              </div>
            )}

            {isSearchPage && (
              <div id="filterPanel_searchInp">
                <Input
                  id="searchInp"
                  autoFocus={true}
                  label="Название или цветок в составе"
                  value={searchVal}
                  setValue={(e) => setSearchVal(e.target.value)}
                />
              </div>
            )}
          </div>
          {list.length ? (
            <div className="catalog_productList">
              <div className="productList_list">{list}</div>
            </div>
          ) : !alreadyBeenFetched ? (
            <div></div>
          ) : (
            <div className="productList_list_noProduct">
              К сожалению, таких товаров нет :(
            </div>
          )}
        </div>
        <Slider isShow={productStore.isLoading} />
      </div>
    );
  }
);
