import { action, makeObservable, observable, runInAction } from "mobx";
import { HTTPservice } from "../services/HTTPservice";
import {
  TPricecForUpdate,
  TProduct,
  TProductForCreate,
  TProductForUpdate,
  TProductWithComponents,
} from "./types";
export type TProductsSortON =
  | "id_DESC"
  | "price_ASC"
  | "price_DESC"
  | "name_ASC"
  | "name_DESC"
  | "no";
type TFetchOptions = {
  categoryIdForFetch?: string;
  sortOn?: TProductsSortON;
  searchOn?: string;
  priceOn?: string;
  offset?: number;
  includedArchived?: boolean;
};
export class ProductStore {
  constructor() {
    makeObservable(this, {
      products: observable,
      oneProduct: observable,
      categoryIdForFetch: observable,
      sortOn: observable,
      searchOn: observable,
      priceOn: observable,
      isLoading: observable,
      offset: observable,
      fetchProducts: action,
      fetchProductsWithComponentId: action,
      fetchOneProduct: action,
      setOptionsAndFetchAll: action,
      getMoreProducts: action,
    });
  }
  isLoading: boolean = false;
  products: TProduct[] = [];
  oneProduct: TProductWithComponents | undefined;
  categoryIdForFetch: string | undefined;
  sortOn: TProductsSortON | undefined;
  searchOn: string | undefined;
  priceOn: string | undefined;
  count: number = 0;
  offset: number = 0;
  includedArchived?: boolean;
  private limit: number = 12;
  fetchingProduct: number | null = null;
  private fetchingMoreProductInProcess: boolean = false;

  getMoreProducts = async () => {
    if (this.fetchingMoreProductInProcess) return;
    this.fetchingMoreProductInProcess = true;

    runInAction(() => (this.offset += this.limit));
    await this.fetchProducts({
      categoryIdForFetch: this.categoryIdForFetch,
      sortOn: this.sortOn,
      searchOn: this.searchOn,
      priceOn: this.priceOn,
      offset: this.offset,
      includedArchived: this.includedArchived,
    });
    this.fetchingMoreProductInProcess = false;
  };

  async setOptionsAndFetchAll(
    {
      categoryIdForFetch,
      sortOn,
      searchOn,
      priceOn,
      includedArchived,
    }: TFetchOptions,
    otherReset: boolean = false
  ) {
    if (categoryIdForFetch || sortOn || searchOn || priceOn || otherReset)
      runInAction(() => (this.offset = 0));
    // if (categoryIdForFetch)
    //   runInAction(() => (this.categoryIdForFetch = categoryIdForFetch));
    if (sortOn) runInAction(() => (this.sortOn = sortOn));
    // if (searchOn) runInAction(() => (this.searchOn = searchOn));
    // if (priceOn) runInAction(() => (this.priceOn = priceOn));
    //variable for send settings to reset some options
    if (categoryIdForFetch !== "setUndefined" && categoryIdForFetch)
      runInAction(() => (this.categoryIdForFetch = categoryIdForFetch));
    if (categoryIdForFetch === "setUndefined")
      runInAction(() => (this.categoryIdForFetch = undefined));
    if (searchOn !== "setUndefined" && searchOn)
      runInAction(() => (this.searchOn = searchOn));
    if (searchOn === "setUndefined")
      runInAction(() => (this.searchOn = undefined));
    if (priceOn !== "setUndefined" && priceOn)
      runInAction(() => (this.priceOn = priceOn));
    if (priceOn === "setUndefined")
      runInAction(() => (this.priceOn = undefined));

    runInAction(() => (this.includedArchived = includedArchived));

    if (otherReset) {
      if (!categoryIdForFetch)
        runInAction(() => (this.categoryIdForFetch = undefined));
      if (!sortOn) runInAction(() => (this.sortOn = undefined));
      if (!searchOn) runInAction(() => (this.searchOn = undefined));
      if (!priceOn) runInAction(() => (this.priceOn = undefined));
    }
    return await this.fetchProducts({
      categoryIdForFetch: this.categoryIdForFetch,
      sortOn: this.sortOn,
      searchOn: this.searchOn,
      priceOn: this.priceOn,
      offset: this.offset,
      includedArchived: this.includedArchived,
    });
  }

  //to do private
  public async fetchProducts(options?: TFetchOptions) {
    runInAction(() => (this.isLoading = true));
    const query = new URLSearchParams();
    let fetchWithPaginate = options?.offset !== undefined;
    if (options?.categoryIdForFetch)
      query.append("categoryId", options.categoryIdForFetch);
    if (options?.sortOn) {
      const [orderby, dir] = options?.sortOn.split("_");
      if (orderby === "price") fetchWithPaginate = false; // сортировка по цене работает только до лимита, поэтому убираем пагинацию когда по цене сортируем
      query.append("orderby", orderby);
      query.append("direction", dir);
    }
    if (options?.searchOn) query.append("name", options.searchOn);
    if (options?.priceOn) query.append("price", options.priceOn);
    if (options?.includedArchived) query.append("includedArchived", "true");
    if (fetchWithPaginate) {
      query.append("offset", options!.offset!.toString());
      query.append("limit", this.limit.toString());
    }
    const queryString = query.toString();
    const res = await HTTPservice.get<{ items: TProduct[]; count: number }>(
      `products?${queryString}`
    );
    if (this.offset) {
      runInAction(() => {
        this.products = [
          ...this.products,
          // ...res.items,
          ...res.items.filter(
            (newPr) => !this.products.map((v) => v.id).includes(newPr.id)
          ), //бэк иногда возвращает один элемент по 2 раза на пагинации по алфавиту
        ];
        this.count = res.count;
      });
    } else {
      runInAction(() => {
        this.products = res.items;
        this.count = res.count;
      });
    }
    runInAction(() => (this.isLoading = false));
    return res;
  }

  public async fetchOneProduct(id: string | number) {
    runInAction(() => {
      this.isLoading = true;
      this.fetchingProduct = +id;
    });
    const res = await HTTPservice.get<TProductWithComponents>(`products/${id}`);
    runInAction(() => {
      this.oneProduct = res;
      this.isLoading = false;
      this.fetchingProduct = null;
    });

    return res;
  }

  public async fetchProductsWithComponentId(ids: (string | number)[]) {
    runInAction(() => {
      this.isLoading = true;
    });
    const query = new URLSearchParams();
    query.append("componentsId", ids.join(", "));
    const res = await HTTPservice.get<TProductWithComponents[]>(
      `products/components?${query.toString()}`,
      true
    );
    runInAction(() => {
      this.isLoading = false;
    });

    return res;
  }

  public async updatePricesPDs(pricesForUpdate: TPricecForUpdate) {
    if (!pricesForUpdate.components.length || !pricesForUpdate.PD.length)
      return;
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await HTTPservice.put<boolean>(
      `products/updatePrices`,
      pricesForUpdate,
      true
    );
    runInAction(() => {
      this.isLoading = false;
    });

    return res;
  }

  public async addProduct(product: TProductForCreate) {
    const res = await HTTPservice.post<TProduct>("products", product, true);
    this.fetchProducts();
    return res;
  }

  public async updateProduct(body: TProductForUpdate) {
    const res = await HTTPservice.put<TProduct>(
      `products/${body.id}`,
      body,
      true
    );
    // if (res === 401) throw new Error("not auth");
    // else {
    this.fetchOneProduct(body.id);
    return res;
    // }
  }

  public patchProduct = async (id: number, options: Partial<TProduct>) => {
    const isSuccess = await HTTPservice.patch<[number]>(
      `products/${id}`,
      options,
      true
    );
    if (isSuccess) {
      runInAction(() => {
        this.products = this.products.map((p) => {
          if (p.id === id) return { ...p, ...options };
          else return p;
        });
      });
    }
    return isSuccess;
  };

  public async deleteProduct(id: number) {
    const res = await HTTPservice.delete(`products/${id}`, true);
    this.fetchProducts();
    return res;
  }
}

export interface IProductStore extends ProductStore {}
