import "./style.css";
import logo from "../../img/logoRose.png";
// import search from "../../img/search.svg";
// import userImg from "../../img/userIcon.svg";
// import basketImg from "../../img/basketImg.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { stores } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { toast } from "react-hot-toast";
import { svgDown } from "../../img/down";
import { basketSVG } from "../../img/basketImg";
import { userSVG } from "../../img/userIcon";
import { searchSVG } from "../../img/searchIcon";
export const Header: FC<{ isAdminPanel?: boolean }> = observer(
  ({ isAdminPanel = false }) => {
    // const place = useLocation();
    const title = stores.helpUIStore.title;
    const { orderStore, userStore, categoryStore, settingStore } = stores;
    const [isOpenBrgMenu, setIsOpenBrgMenu] = useState(false);
    const [isOpenCatalogOnBrg, setIsOpenCatalogOnBrg] = useState(true);

    useEffect(() => {
      if (categoryStore.categories.length || isAdminPanel) return;
      categoryStore.fetchCategories();
    }, []); // eslint-disable-line

    const navBar = isAdminPanel ? (
      <nav className="header_btnBar">
        <NavLink to="/admin/orders">ЗАКАЗЫ</NavLink>
        <NavLink to="/admin/products">ПРОДУКТЫ</NavLink>
        <NavLink to="/admin/components">КОМПОНЕНТЫ</NavLink>
        <NavLink to="/admin/categories">КАТЕГОРИИ</NavLink>
        <NavLink to="/admin/prices">СМЕНА ЦЕН</NavLink>
        <NavLink to="/admin/reviews">ОТЗЫВЫ</NavLink>
        <NavLink to="/admin/settings">НАСТРОЙКИ</NavLink>
      </nav>
    ) : (
      <nav className="header_btnBar">
        <NavLink to="/catalog">КАТАЛОГ</NavLink>
        <NavLink to="/about">О НАС</NavLink>
        <NavLink to="/reviews">ОТЗЫВЫ</NavLink>
        <NavLink to="/delivery">ОПЛАТА И ДОСТАВКА</NavLink>
      </nav>
    );
    const navigate = useNavigate();

    const {
      settings: {
        is_sales_close: isSalesClose,
        sales_close_reason: closeReason,
      },
    } = settingStore;
    const basket = orderStore.productsInOrder.length ? (
      <Link to="/newOrder">
        <div className="header_backet" title="Перейти в корзину">
          {/* <img src={basketImg} alt="Перейти в корзину" /> */}
          <div className="icon_header">{basketSVG}</div>
          <span className="icon_amount">
            {orderStore.productsInOrder.length}
          </span>
        </div>
      </Link>
    ) : (
      <div
        className="header_backet"
        onClick={() => {
          toast("Добавьте товар в корзину", { icon: "🛒", duration: 2000 });
        }}
      >
        {/* <img src={basketImg} alt="Корзина пуста" title="Корзина пуста" /> */}
        <div title="Корзина пуста" className="icon_header">
          {basketSVG}
        </div>
      </div>
    );
    const userIcon = userStore.isAdmin ? (
      <div
        title="Выйти из режима администрирования"
        className="icon_header"
        onClick={() => userStore.setIsAdmin(false)}
      >
        {userSVG}
      </div>
    ) : (
      // <img
      //   src={userImg}
      //   onClick={() => userStore.setIsAdmin(false)}
      //   alt="Выйти"
      //   title="Выйти"
      // />
      <></>
    );
    const catalogIconClass = isOpenCatalogOnBrg
      ? "menuBrg_svgIcon menuBrg_svgIcon_open"
      : "menuBrg_svgIcon";
    const catalogCategoriesClass = isOpenCatalogOnBrg
      ? "categoriesBrg"
      : "categoriesBrg hide_categoriesBrg";
    const burgerNav = (
      <nav role="navigation" className="burgermenu_header">
        <div id="menuToggle">
          <input
            type="checkbox"
            checked={isOpenBrgMenu}
            onClick={() => setIsOpenBrgMenu(!isOpenBrgMenu)}
            readOnly={true}
          />

          <span></span>
          <span></span>
          <span></span>

          <div id="menu">
            <div className="menuBrg_catalog">
              <a // eslint-disable-line
                href="#"
                onClick={() => setIsOpenCatalogOnBrg(!isOpenCatalogOnBrg)}
              >
                КАТАЛОГ
              </a>
              <div className={catalogIconClass}>{svgDown}</div>
            </div>
            <div className={catalogCategoriesClass}>
              <NavLink
                key={"all"}
                onClick={() => setIsOpenBrgMenu(false)}
                to={"/catalog/all"}
              >
                Все товары
              </NavLink>
              {categoryStore.categories.map(({ id, name }) => {
                return (
                  <NavLink
                    key={id}
                    onClick={() => setIsOpenBrgMenu(false)}
                    to={"/catalog/" + id.toString()}
                  >
                    {name}
                  </NavLink>
                );
              })}
            </div>
            <NavLink onClick={() => setIsOpenBrgMenu(false)} to="/about">
              О НАС
            </NavLink>
            <NavLink onClick={() => setIsOpenBrgMenu(false)} to="/reviews">
              ОТЗЫВЫ
            </NavLink>
            <NavLink onClick={() => setIsOpenBrgMenu(false)} to="/delivery">
              ОПЛАТА И ДОСТАВКА
            </NavLink>
          </div>
        </div>
      </nav>
    );

    return (
      <div className="header_wrapper">
        <div className="header_NavBarWrapper">
          <div className="header_NavBarContent">
            <div className="header_logo">
              <Link to="/">
                <img src={logo} alt="logo" title="На главную" />
              </Link>
            </div>
            {navBar}
            {!isAdminPanel && (
              <div className="header_icons">
                {!isSalesClose && basket}
                <Link to="/search">
                  <div
                    title="Поиск по названию"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      navigate("/admin");
                    }}
                    className="icon_header"
                  >
                    {searchSVG}
                  </div>

                  {/* <img
                    src={search}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      navigate("/admin");
                    }}
                    alt="Поиск по названию"
                    title="Поиск по названию"

                  /> */}
                </Link>
                {userIcon}
              </div>
            )}
            {burgerNav}
          </div>
        </div>
        {isSalesClose ? (
          <div className="header_globalTitle_message">{closeReason}</div>
        ) : (
          <div className="header_globalTitle">Доставка цветов в Братске</div>
        )}
        <div className="header_title">{title?.toUpperCase()}</div>
      </div>
    );
  }
);
