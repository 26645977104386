import { action, makeObservable, observable, runInAction } from "mobx";
import { HTTPservice } from "../services/HTTPservice";
import { TComponent } from "./types";
type TComponentForCreate = {
  name: string;
  price: number;
  photos?: string[];
  is_archive?: boolean;
  is_show_to_client: boolean;
};
export class ComponentStore {
  constructor() {
    makeObservable(this, {
      components: observable,
      fetchComponents: action,
    });
  }

  components: TComponent[] = [];

  public async fetchComponents() {
    const res = await HTTPservice.get<TComponent[]>("components_products");
    runInAction(() => {
      // this.components = res.sort((a, b) => a.id - b.id);
      this.components = res.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  public async addComponent(body: TComponentForCreate) {
    const res = await HTTPservice.post<TComponent>(
      "components_products",
      body,
      true
    );
    // if (res === 401) throw new Error("not auth");
    // else {
    this.fetchComponents();
    return res;
    // }
  }

  public async updateComponent(body: TComponentForCreate & { id: number }) {
    const res = await HTTPservice.put<TComponent>(
      `components_products/${body.id}`,
      body,
      true
    );
    // if (res === 401) throw new Error("not auth");
    // else {
    this.fetchComponents();
    return res;
    // }
  }

  public async deleteComponent(id: number) {
    const res = await HTTPservice.delete(`components_products/${id}`, true);
    // if (res === 401) throw new Error("not auth");
    // else {
    this.fetchComponents();
    return res;
    // }
  }
}
