import { FC, useEffect, useRef, useState } from "react";
import { TuseFieldSetState, TuseFieldState } from "../../utils/formUtils";
import "./style.css";
import { getBratskTimeNow } from "../../utils/timeDate";
import { observer } from "mobx-react-lite";
import { stores } from "../../stores/rootStore";
import { TDaDataAddress } from "../../stores/types";

type TInput = {
  value: string;
  setValue: (e: any) => void;
  label: string;
  id: string;
  width?: number;
  autoFocus?: boolean;
  title?: string;
  type?: "input" | "textarea" | "pass" | "dateTime";
  clazz?: string;
};
export const Input: FC<TInput> = ({
  value,
  setValue,
  label,
  id,
  width = 300,
  autoFocus,
  title,
  type = "input",
  clazz = "form__field field__valid",
}) => {
  let input = (
    <input
      type={type === "input" ? "text" : "password"}
      className={clazz}
      id={id}
      spellCheck={false}
      placeholder={id}
      title={title}
      autoFocus={document.body.clientWidth > 950 ? autoFocus : false}
      value={value}
      onChange={setValue}
      required
    />
  );
  if (type === "dateTime") {
    // const valueDT = value;
    // не раньше чем текущая дата
    // const setValueDT = (e: any) => {
    //   if (e.target.value) setValue(e);
    // };
    // console.log(localTimeNow.toISOString().slice(0, 16));
    input = (
      <input
        title={title}
        min={getBratskTimeNow()}
        autoFocus={autoFocus}
        type="datetime-local" // много где не поддерживается datetime-local еще
        className={clazz}
        id={id}
        placeholder={id}
        spellCheck={false}
        value={value}
        onChange={setValue}
        // inputMode=''
      />
    );
  }

  if (type === "textarea")
    input = (
      <textarea
        className="form__field field__valid"
        id={id}
        placeholder={id}
        spellCheck={false}
        onChange={setValue}
        value={value}
        required
      ></textarea>
    );
  return (
    <div className="form__group field" style={{ width }}>
      {input}
      <label htmlFor={id} className="form__label">
        {label}
      </label>
    </div>
  );
};

type TInputWithValidate = {
  state: TuseFieldState<string>;
  setState: TuseFieldSetState<string>;
  label: string;
  id: string;
  width?: number;
  type?: "input" | "textarea" | "pass" | "dateTime";
  typemod?: "numeric";
  validate?: "notEmpty";
  minLength?: number;
  autoFocus?: boolean;
  forPhone?: "req" | "opt" | "no";
};
export const InputWithValidate: FC<TInputWithValidate> = ({
  state,
  setState,
  label,
  autoFocus,
  id,
  width = 300,
  type = "input",
  typemod,
  validate,
  minLength = 0,
  forPhone = "no",
}) => {
  const { isObserve, isValid, value, hint } = state;

  const setIsValid = (isOk: boolean, title: string = "") => {
    if (!isOk && isValid) {
      setState((state: any) => ({ ...state, isValid: false, hint: title }));
    }
    if (isOk && !isValid)
      setState((state: any) => ({ ...state, isValid: true, hint: "" }));
  };
  const setValue = (e: any) =>
    setState((state: any) => ({ ...state, value: e.target.value }));

  const setOnlyNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(+e.target.value)) return;
    setValue(e);
  };
  const setPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const el = e.target,
      clearVal = el.dataset.phoneClear,
      matrix = "+7(___) ___-__-__",
      def = matrix.replace(/\D/g, "");
    let val = e.target.value.replace(/\D/g, ""),
      i = 0;
    if (clearVal !== "false" && e.type === "blur") {
      // eslint-disable-next-line
      if (val.length < matrix.match(/([\_\d])/g)!.length) {
        setState((state: any) => ({ ...state, value: "" }));
        return;
      }
    }
    if (def.length >= val.length) val = def;
    const res = matrix.replace(/./g, function (a) {
      return /[_\d]/.test(a) && i < val.length
        ? val.charAt(i++)
        : i >= val.length
        ? ""
        : a;
    });
    setState((state: any) => ({ ...state, value: res }));
  };

  useEffect(() => {
    if (minLength) {
      setIsValid(
        value.length >= minLength,
        `Минимальное кол-во символов ${minLength}`
      );
    }
    if (forPhone === "req") {
      setIsValid(value.length === 17, `Введите верный номер`);
    }
  }, [state]); // eslint-disable-line

  const clazz =
    !isValid && isObserve
      ? "form__field field__notValid"
      : "form__field field__valid";
  let onChange = setValue;
  if (typemod === "numeric") onChange = setOnlyNumber;
  if (forPhone !== "no") onChange = setPhoneNumber;

  let input = (
    <input
      title={hint}
      autoFocus={autoFocus}
      type={type === "input" ? "text" : "password"}
      className={clazz}
      id={id}
      placeholder={id}
      spellCheck={false}
      value={value}
      onChange={onChange}
      inputMode={typemod === "numeric" ? "numeric" : "text"}
    />
  );
  if (type === "dateTime")
    input = (
      <input
        title={hint}
        autoFocus={autoFocus}
        type="datetime-local"
        className={clazz}
        id={id}
        spellCheck={false}
        placeholder={id}
        value={value}
        onChange={onChange}
        // inputMode=''
      />
    );

  if (type === "textarea")
    input = (
      <textarea
        title={hint}
        className={clazz}
        id={id}
        spellCheck={false}
        placeholder={id}
        onChange={setValue}
        value={value}
        required
      ></textarea>
    );
  return (
    <div className="form__group field" style={{ width }}>
      {input}
      <label htmlFor={id} className="form__label">
        {label}
      </label>
    </div>
  );
};

export const Select: FC<TInput & { options: string[] }> = ({
  value,
  setValue,
  label,
  options,
  id,
  width = 300,
}) => {
  const optionsList = options.map((v) => (
    <option key={v} value={v}>
      {v}
    </option>
  ));
  return (
    <div className="form__group field" style={{ width }}>
      <select
        className="form__field field__valid"
        id={id}
        value={value}
        onChange={setValue}
      >
        {optionsList}
      </select>

      <label htmlFor={id} className="form__label">
        {label}
      </label>
    </div>
  );
};
export const SelectWithValidate: FC<
  TInputWithValidate & { options: string[]; notEmpty?: boolean }
> = ({ state, setState, label, options, id, width = 300, notEmpty = true }) => {
  const optionsList = options.map((v) => (
    <option key={v} value={v}>
      {v}
    </option>
  ));
  const { isObserve, isValid, value, hint } = state;

  const setIsValid = (isOk: boolean, title: string = "") => {
    if (!isOk && isValid) {
      setState((state: any) => ({ ...state, isValid: false, hint: title }));
    }
    if (isOk && !isValid)
      setState((state: any) => ({ ...state, isValid: true, hint: "" }));
  };
  const setValue = (e: any) =>
    setState((state: any) => ({ ...state, value: e.target.value }));

  useEffect(() => {
    if (notEmpty) {
      setIsValid(value.length >= 1, `Необходимо выбрать вариант`);
    }
  }, [state]); // eslint-disable-line

  const clazz =
    !isValid && isObserve
      ? "form__field field__notValid"
      : "form__field field__valid";

  return (
    <div className="form__group field" style={{ width }}>
      <select
        className={clazz}
        title={hint}
        id={id}
        value={value}
        onChange={setValue}
      >
        {optionsList}
      </select>

      <label htmlFor={id} className="form__label">
        {label}
      </label>
    </div>
  );
};

export const SelectAddress: FC<TInput> = observer(
  ({
    value,
    setValue,
    label,
    id,
    width = 300,
    title,
    clazz = "form__field field__valid",
  }) => {
    const { addressStore } = stores;
    const inpRef = useRef<HTMLTextAreaElement>(null);
    const [hideOptions, setHideOptions] = useState(true);
    const prepareAdress = ({
      data: { house, flat },
      value,
    }: TDaDataAddress): string => {
      const addressStartOnSettlementOrOtherCity = value
        .split(",")
        .slice(2)
        .join(",");
      if (flat) return addressStartOnSettlementOrOtherCity;
      if (house) return addressStartOnSettlementOrOtherCity + " ";
      return addressStartOnSettlementOrOtherCity + ", ";
    };
    const options = addressStore.addressSuggetions?.map((v, i) => {
      const addressString = prepareAdress(v);
      return (
        <div
          key={v.unrestricted_value}
          onClick={() => {
            setValue(addressString);
            addressStore.setAddress(v);
            inpRef.current && inpRef.current.focus();
            setHideOptions(true);
          }}
        >
          {addressString}
        </div>
      );
    });

    const classOptions = hideOptions
      ? "input_address_options hide_address_options"
      : "input_address_options";
    return (
      <div className="form__group field input_address" style={{ width }}>
        <textarea
          className={clazz}
          id={id}
          placeholder={id}
          title={title}
          value={value}
          spellCheck={false}
          ref={inpRef}
          onChange={(e) => {
            addressStore.fetchAddress(e.target.value);
            setHideOptions(false);
            setValue(e.target.value);
          }}
          onBlur={() => {
            if (
              addressStore.addressSuggetions[0] &&
              prepareAdress(addressStore.addressSuggetions[0]) === value
            ) {
              if (addressStore.address !== addressStore.addressSuggetions[0])
                addressStore.setAddress(addressStore.addressSuggetions[0]);
            } else {
              addressStore.setAddress(undefined);
            }
            setTimeout(() => setHideOptions(true), 100);
          }}
        ></textarea>

        <label htmlFor={id} className="form__label">
          {label}
        </label>
        <div className={classOptions}>{options}</div>
      </div>
    );
  }
);
