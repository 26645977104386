export const basketSVG = (
  <svg
    // width="19"
    // height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4 5.69995L15.3 17.5999H3.69998L1.59998 5.69995H17.4Z"
      //   stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M12.9 5.6999C12.9 3.5999 11.9 1.3999 9.49998 1.3999C7.09998 1.3999 6.09998 3.4999 6.09998 5.6999"
      //   stroke="black"
      strokeMiterlimit="10"
    />
  </svg>
);
