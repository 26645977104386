import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect } from "react";
import { Select } from "../../components/inputs/inputs";
import { stores } from "../../stores/rootStore";
import toast from "react-hot-toast";
import cross from "../../img/cross.svg";

import "./style.css";
import { TOrderStatus } from "../../stores/types";
import { Link } from "react-router-dom";
import { prepareDateTime } from "../../utils/timeDate";
import { getNeedSizePhoto } from "../../utils/galleryUtils";
import { ORDERSTATUSES } from "./Orders";

export const PopUpOrder: FC<{
  hidePopUp: () => void;
  idOrder: number;
  popUpMode: "hide" | "view" | "edit";
}> = observer(({ hidePopUp, idOrder, popUpMode }) => {
  const { orderStore } = stores;

  const escFunction = useCallback((evt: any) => {
    if (evt.key === "Escape") {
      hidePopUp();
    }
  }, []); // eslint-disable-line
  useEffect(() => {
    document.addEventListener("keydown", (evt) => escFunction(evt), false);

    return () => {
      document.removeEventListener("keydown", (evt) => escFunction(evt), false);
    };
  }, [escFunction]);

  const orderInPopup = orderStore.orders.find((ord) => ord.id === idOrder);
  if (!orderInPopup) return <></>;

  const {
    client_fio,
    client_phone,
    id,
    products,
    receiver_name,
    receiver_phone,
    status,
    time_create_order,
    time_delivery,
    comment,
    is_need_note,
    is_need_photo,
    note_message,
    receiver_address,
    delivery_cost,
    time_close_order,
    total_sum,
  } = orderInPopup;

  const timeCreate = prepareDateTime(time_create_order, "dd month year time");
  const timeDelivery = prepareDateTime(time_delivery, "dd month year time");
  const timeClose = prepareDateTime(time_close_order, "dd month year time");

  const productList = products.map(
    ({ name, amount, photos, price, total_price, refOnProduct }, i) => {
      //   const onChangeAmount = (action: "inc" | "dec") => {
      //     if (amount === 1 && action === "dec") return;
      //     const newAmount = action === "inc" ? amount + 1 : amount - 1;
      //     const newProducts = [...productsInOrder];
      //     newProducts[i] = {
      //       ...newProducts[i],
      //       amount: newAmount,
      //       total_price: price * newAmount,
      //     };
      //     setProductsInOrder(newProducts);
      //   };
      return (
        <div key={refOnProduct} className="aop_productItem">
          {/* <div className="pi_photo">
          </div> */}
          <Link
            target="_blank"
            title={`Открыть страницу с ${name}`}
            className="aop_photo"
            to={`/product/${refOnProduct}`}
          >
            <img src={getNeedSizePhoto(photos, "medium")[0]} alt={name} />
          </Link>
          <Link
            target="_blank"
            title={`Открыть страницу с ${name}`}
            className="pi_name"
            to={`/product/${refOnProduct}`}
          >
            {name}
          </Link>
          <div title="Количество в заказе">{amount + " шт"}</div>

          {/* <div className="pi_amount">
            <div
              title="Уменьшить кол-во товара в заказе"
              className="pi_amount_op"
              onClick={() => onChangeAmount("dec")}
            >
              -
            </div>
            <div title="Количество в заказе">{amount}</div>
            <div
              title="Увеличить кол-во товара в заказе"
              className="pi_amount_op"
              onClick={() => onChangeAmount("inc")}
            >
              +
            </div>
          </div> */}
          <div className="pi_price">{price} ₽/шт</div>
          {/* <div
            className="pi_del_product"
            title="Удалить из корзины"
            onClick={() => {
              setProductsInOrder([
                ...productsInOrder.filter((p) => p.idPD !== idPD),
              ]);
            }}
          >
            {svgClose}
          </div> */}
        </div>
      );
    }
  );
  const onChangeStatus = (newStatus: TOrderStatus) => {
    const myPromise = orderStore.updateOrder({
      ...orderInPopup,
      status: newStatus,
    });
    toast.promise(myPromise, {
      loading: "Обновление статуса",
      success: "Статус обновлен",
      error: "Упс, что-то пошло не так",
    });
  };

  return (
    <div className="adminOrders_popUp" onClick={hidePopUp}>
      <div className="ao_popUp" onClick={(e) => e.stopPropagation()}>
        <div className="aop_title">{`Заказ № ${id + 2000}`}</div>
        <div className="aop_content">
          <div className="aop_orderInfo">
            {/* <div className="aop_infoItem">
              <span>Статус:</span> {status}
            </div> */}
            <div className="aop_infoItem">
              <span>Время создания:</span> {timeCreate}
            </div>
            <div className="aop_infoItem">
              <span>Время закрытия:</span> {timeClose}
            </div>

            <div className="aop_infoItem">
              <span>Нужна фотография:</span> {is_need_photo ? "✅" : "❌"}
            </div>
            <div className="aop_infoItem">
              <span>Нужна записка:</span> {is_need_note ? "✅" : "❌"}
            </div>
            {is_need_note && (
              <div className="aop_infoItem">
                <span>Текст записки:</span> {note_message}
              </div>
            )}
            <div className="aop_infoItem">
              <span>Комментарий:</span> {comment}
            </div>
          </div>
          <div className="aop_contacts">
            <div className="aop_infoItem">
              <span>Имя клиента:</span> {client_fio}
            </div>
            <div className="aop_infoItem">
              <span>Телефон клиента:</span> {client_phone}
            </div>
            <div className="aop_infoItem">
              <span>Имя получателя:</span> {receiver_name}
            </div>
            <div className="aop_infoItem">
              <span>Телефон получателя:</span> {receiver_phone}
            </div>
            <div className="aop_infoItem">
              <span>Время доставки:</span> {timeDelivery}
            </div>
            <div className="aop_infoItem">
              <span>Адрес доставки:</span> {receiver_address}
            </div>
            <Select
              id="status"
              label="Статус"
              value={status}
              width={200}
              options={ORDERSTATUSES}
              setValue={(e) => {
                onChangeStatus(e.target.value);
              }}
            />
          </div>
          <div className="aop_productList">
            <div className="aop_infoItem">
              <span>Товары:</span>
            </div>
            {productList}
            {delivery_cost ? (
              <>
                <div className="aop_infoItem">
                  <span>Стоимость товаров:</span> {total_sum} ₽
                </div>
                <div className="aop_infoItem">
                  <span>Стоимость доставки:</span> {delivery_cost} ₽
                </div>
                <div className="aop_infoItem">
                  <span>Итого:</span> {delivery_cost + total_sum} ₽
                </div>
              </>
            ) : (
              <>
                <div className="aop_infoItem">
                  <span>Итого:</span> {total_sum} ₽
                </div>
                <div className="aop_info_sum">(без учета доставки)</div>
              </>
            )}
          </div>
        </div>
        <img
          className="aop_close"
          src={cross}
          onClick={hidePopUp}
          alt="Закрыть"
          title="Закрыть"
        />
      </div>
    </div>
  );
});
