export const userSVG = (
  <svg
    // width="19"
    // height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeWidth="1.2"
      d="M10.3998 10.9C13.2717 10.9 15.5998 8.57188 15.5998 5.7C15.5998 2.82812 13.2717 0.5 10.3998 0.5C7.52795 0.5 5.19983 2.82812 5.19983 5.7C5.19983 8.57188 7.52795 10.9 10.3998 10.9Z"
      stroke="#fff"
      strokeMiterlimit="10"
    ></path>
    <path
      strokeWidth="1.2"
      d="M18.7999 17.3C18.7999 14.4 16.7999 12.5 10.3999 12.5C3.99988 12.5 1.99988 14.4 1.99988 17.3"
      stroke="#fff"
      strokeMiterlimit="10"
    ></path>
  </svg>
);
