import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import {
  TProduct,
  TProductDefinationsBase,
  TProductInOrder,
} from "../../stores/types";
import ImageGallery from "react-image-gallery";
import "./image-gallery.css";
import { basketSVG } from "../../img/basketImg";
import rubsvg from "../../img/rub.svg";

import "./style.css";
import { stores } from "../../stores/rootStore";
import { getPhotoForGallery } from "../../utils/galleryUtils";
import { Link, useNavigate } from "react-router-dom";
import { YMaddProductToBasket } from "../../utils/YMecomerce";
import {
  renderLeftNav,
  renderRightNav,
} from "../../components/imageGallery/imageGalleryComponents";
export const ProductItem: FC<{ product: TProduct }> = observer(
  ({ product }) => {
    const navigate = useNavigate();
    const {
      productStore,
      orderStore,
      settingStore: {
        settings: { is_sales_close: isSalesClose },
      },
    } = stores;
    const [activPD, setactivPD] = useState<
      TProductDefinationsBase & { id: number }
    >();

    const choosePd = (index: number) => {
      setactivPD(ProductDefinations[index]);
    };

    const { ProductDefinations, name, id: idProduct, Category } = product;
    const productDefinationsBtns = ProductDefinations.map(
      ({ id, name_variant }, i) => {
        let clazz =
          activPD?.id === id ? "pd_btn pd_btn_active" : "pd_btn pd_btn_passive";
        if (i === 0) clazz += " pd_btn_first";
        return (
          <div className={clazz} key={id} onClick={() => choosePd(i)}>
            {name_variant}
          </div>
        );
      }
    );
    useEffect(() => {
      setactivPD(ProductDefinations[0]);
    }, [product]); // eslint-disable-line
    useEffect(() => {
      if (productStore.sortOn === "price_ASC") {
        setactivPD(ProductDefinations[0]);
      }
      if (productStore.sortOn === "price_DESC") {
        setactivPD(ProductDefinations[ProductDefinations.length - 1]);
      }
    }, [productStore.products]); // eslint-disable-line
    const goToProduct = () => {
      navigate(`/product/${idProduct}?pd=${activPD?.id}`);
    };

    const addInBasket = () => {
      if (!activPD) return;

      const {
        name_variant,
        photos,
        price,
        description = "",
        id: idPD,
      } = activPD;
      const isManyPD = ProductDefinations.length > 1;

      const productToBasket: TProductInOrder = {
        idProduct,
        idPD,
        amount: 1,
        components: {},
        description,
        name: isManyPD ? `${name} ${name_variant}` : name,
        photos,
        price,
        total_price: price,
        refOnProduct: `${idProduct}?pd=${idPD}`,
        prodName: name,
        ...(isManyPD && {
          PDName: name_variant,
        }),
      };
      orderStore.setProductsInOrder([
        ...orderStore.productsInOrder,
        productToBasket,
      ]);
      YMaddProductToBasket({
        id: idPD.toString(),
        category: Category.name,
        name: productToBasket.name,
        price,
        ...(isManyPD && {
          variant: name_variant,
        }),
      });
    };
    const basketBtn = orderStore.productsInOrder
      .map((v) => v.idPD)
      .includes(activPD?.id ?? 0) ? (
      <Link to={"/newOrder"} title="Перейти в корзину">
        <div className="item_basket rose_btn item_basket_active">
          <div className="item_basket_img">{basketSVG}</div>
          <span>В корзине</span>
        </div>
      </Link>
    ) : (
      <div
        title="Добавить в корзину"
        className="item_basket rose_btn"
        onClick={addInBasket}
      >
        {basketSVG}

        <span>В корзину</span>
      </div>
    );

    return (
      <div className="item_wrapper">
        <ImageGallery
          onClick={goToProduct}
          items={getPhotoForGallery(
            activPD?.photos,
            false,
            `Перейти на страницу ${name}`
          )}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          showBullets={(activPD?.photos.length ?? 0) > 1}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
        />
        <div className="pi_price_box">
          <div
            className="item_price"
            title={`Перейти на страницу ${name}`}
            onClick={goToProduct}
          >
            {activPD?.price} {/* ₽{rubSVG} */}
          </div>
          <img src={rubsvg} alt="" />
        </div>
        <div
          className="item_title"
          title={`Перейти на страницу ${name}`}
          onClick={goToProduct}
        >
          {name}{" "}
        </div>

        {productDefinationsBtns.length > 1 && (
          <div className="item_pd_btnBar">{productDefinationsBtns}</div>
        )}
        {!isSalesClose && basketBtn}
      </div>
    );
  }
);
