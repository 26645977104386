import { action, makeObservable, observable } from "mobx";
import { HTTPservice } from "../services/HTTPservice";
import { TSetting } from "./types";
export class SettingStore {
  constructor() {
    makeObservable(this, {
      settings: observable,
      fetchSettings: action,
      updateSettings: action,
    });

    this.fetchSettings();
  }

  settings: TSetting = { is_sales_close: false, sales_close_reason: "" };

  public async updateSettings({
    is_sales_close,
    sales_close_reason,
  }: TSetting) {
    const res = await HTTPservice.put<TSetting>(
      "settings",
      { is_sales_close, sales_close_reason },
      true
    );
    this.settings = res;
  }

  public async fetchSettings() {
    const res = await HTTPservice.get<TSetting>("settings");
    if (!res) return;
    this.settings = res;
  }
}
