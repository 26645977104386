import { action, makeObservable, observable, runInAction } from "mobx";
import { HTTPservice } from "../services/HTTPservice";
import { TReview, TReviewsInfo } from "./types";

export class ReviewStore {
  constructor() {
    makeObservable(this, {
      reviews: observable,
      reviewsInfo: observable,
      isLoading: observable,
      fetchReviews: action,
      fetchReviewsInfo: action,
      setReviewInfo: action,
      getMoreReview: action,
      offset: observable,
    });
  }
  isLoading: boolean = false;

  reviews: TReview[] = [];

  reviewsInfo: TReviewsInfo = {
    logo_company: "",
    name_company: "",
    rating: 5,
    count: 1,
  };

  offset: number = 0;
  private limit: number = 10;
  count: number = 0;
  private fetchingMoreReviewsInProcess: boolean = false;

  public getMoreReview = async (includeHidden: boolean = false) => {
    if (this.fetchingMoreReviewsInProcess) return;
    this.fetchingMoreReviewsInProcess = true;

    runInAction(() => (this.offset += this.limit));

    await this.fetchReviews(includeHidden, this.offset);
    this.fetchingMoreReviewsInProcess = false;
  };

  public async fetchReviews(includeHidden?: boolean, offset?: number) {
    this.isLoading = true;
    const query = new URLSearchParams();

    if (offset !== undefined) {
      query.append("offset", offset.toString());
      query.append("limit", this.limit.toString());
    }

    if (includeHidden) {
      query.append("full", "true");
    }

    const queryString = query.toString();

    const res = await HTTPservice.get<{ items: TReview[]; count: number }>(
      `reviews?${queryString}`
    );
    if (this.offset) {
      runInAction(() => {
        this.reviews = [...this.reviews, ...res.items];
        this.count = res.count;
      });
    } else {
      runInAction(() => {
        this.reviews = res.items;
        this.count = res.count;
      });
    }
    this.isLoading = false;

    return res;
  }

  public async fetchReviewsInfo(full?: boolean) {
    const query = new URLSearchParams();

    if (full) {
      query.append("full", "true");
    }
    const queryString = query.toString();

    const res = await HTTPservice.get<TReviewsInfo>(
      `reviews/info?${queryString}`
    );

    if (!res) return;
    runInAction(() => {
      this.reviewsInfo = res;
    });

    return res;
  }

  public setReviewInfo(newReviewInfo: TReviewsInfo) {
    this.reviewsInfo = newReviewInfo;
  }

  public async updateReviewsInfo() {
    const res = await HTTPservice.put<TReviewsInfo>(
      `reviews/info`,
      this.reviewsInfo,
      true
    );

    return res;
  }

  public updateReviewsFromGis = async (token: string) => {
    const query = new URLSearchParams();

    query.append("tokenGis", token);
    const queryString = query.toString();

    const res = await HTTPservice.get<{ added: string }>(
      `reviews/updateFromProviders?${queryString}`,
      true
    );
    await this.fetchReviewsInfo(true);

    return res;
  };

  public updateReviewsFromYa = async (body: string, updatefull?: boolean) => {
    const res = await HTTPservice.put<{ added: string; updated: string }>(
      `reviews/yandex?${updatefull ? "updatefull=true" : ""}`,
      JSON.parse(body),
      true
    );

    await this.fetchReviewsInfo(true);

    return res;
  };

  public resetState = () => {
    runInAction(() => {
      this.count = 0;
      this.reviews = [];
    });
  };

  public async updateReviewFromGis(
    token: string,
    id_on_provider: string,
    id: number
  ) {
    const query = new URLSearchParams();

    query.append("tokenGis", token);
    const queryString = query.toString();

    const res = await HTTPservice.put<TReview>(
      `reviews/fromProvider/${id}?${queryString}`,
      { id_on_provider },
      true
    );

    if (res.id) {
      await this.fetchReviewsInfo(true);
      runInAction(
        () =>
          (this.reviews = this.reviews.map((r) => {
            if (r.id === res.id) return res;
            return r;
          }))
      );
    }

    return res;
  }

  public async updateReviewVisible(body: TReview) {
    const res = await HTTPservice.put(
      `reviews/visible/${body.id}`,
      { ...body, photos: JSON.stringify(body.photos) },
      true
    );
    if (res === "success") {
      await this.fetchReviewsInfo(true);
      runInAction(
        () =>
          (this.reviews = this.reviews.map((r) => {
            if (r.id === body.id) return body;
            return r;
          }))
      );
    }
    return `Теперь отображается ${this.reviewsInfo.count}, рейтинг ${this.reviewsInfo.rating}`;
  }
}
