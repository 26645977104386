import { useEffect } from "react";
import { stores } from "../stores/rootStore";
import "./devpay.css";
import cash from "./img/cash.svg";
import credit from "./img/credit.svg";
import delivery from "./img/delivery.svg";

export const DeliveryPay = () => {
  useEffect(() => {
    stores.helpUIStore.setTitle("ОПЛАТА И доставка");
    stores.helpUIStore.setHistory(["delivery"]);
  }, []);
  return (
    <div className="del_pay_wrapper">
      {/* <h2>
          Оплата производится безналичным переводом после подтверждения заказа
          менеджером
        </h2> */}
      <div className="del_pay_pay">
        <h2 className="del_pay_title">СПОСОБЫ ОПЛАТЫ</h2>
        <div className="pay_box">
          <div className="pay_item">
            <img src={cash} alt="Наличные" />
            <div>Наличными при самовывозе</div>
          </div>
          <div className="pay_item">
            <img src={credit} alt="Кредитная карта" />
            <div>Безналичным переводом</div>
            <div className="pay_info">
              оплата производится после подтверждения заказа менеджером
            </div>
          </div>
        </div>
      </div>
      <div className="del_pay_delivery">
        <h2 className="del_pay_title">СТОИМОСТЬ ДОСТАВКИ</h2>
        <div className="del_box">
          <div className="del_box_item">
            <img src={delivery} alt="Доставка" />
            <span>Центральная часть г. Братска 250р</span>
          </div>
          <div className="del_box_item">
            <img src={delivery} alt="Доставка" />
            <span>Энергетик 250р</span>
          </div>
          <div className="del_box_item">
            <img src={delivery} alt="Доставка" />
            <span>Правый берег 350р</span>
          </div>
          <div className="del_box_item">
            <img src={delivery} alt="Доставка" />
            <span>Южный падун, Падун 350р</span>
          </div>
          <div className="del_box_item">
            <img src={delivery} alt="Доставка" />
            <span>Сосновый 400р</span>
          </div>
          <div className="del_box_item">
            <img src={delivery} alt="Доставка" />
            <span>Чекановский 450р</span>
          </div>
          <div className="del_box_item">
            <img src={delivery} alt="Доставка" />
            <span>Стениха 700р</span>
          </div>
          <div className="del_box_item">
            <img src={delivery} alt="Доставка" />
            <span>Порожский, Бикей 800р</span>
          </div>
          <div className="del_box_item">
            <img src={delivery} alt="Доставка" />
            <span>Сухой 1100р</span>
          </div>
          <div className="del_box_item">
            <img src={delivery} alt="Доставка" />
            <span>Другие районы по согласованию</span>
          </div>
        </div>
      </div>
    </div>
  );
};
