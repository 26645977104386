import { Suspense, lazy } from "react";
import "./app.css";
import "./adaptive.css";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import { Header } from "../components/header/Header";
import { Footer } from "../components/footer/Footer";
import { Catalog } from "../catalog/Catalog";
import { Toaster } from "react-hot-toast";
import { Product } from "../product/Product";
import { NewOrder } from "../newOrder/newOrder";
import { DeliveryPay } from "../staticPages/DeliveryPay";
import { About } from "../staticPages/About";
const AuthPage = lazy(() => import("../admin/authPage/AuthPage"));
const Reviews = lazy(() => import("../staticPages/Reviews"));

const App = () => {
  return (
    <>
      <Suspense>
        <Routes>
          <Route path="/*" element={<ClientApp />} />
          <Route path="/admin/*" element={<AuthPage />} />
        </Routes>
      </Suspense>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
          },
        }}
      />
    </>
  );
};

const ClientApp = () => {
  return (
    <div className="app">
      <Header />
      <div className="app_content">
        <Routes>
          <Route path="/" element={<Navigate to="/catalog" />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/catalog/:id" element={<Catalog />} />
          <Route path="/search" element={<Catalog />} />
          <Route path="/product/:id" element={<Product />} />
          {/* <Route index element={<Home />} /> */}
          <Route path="/about" element={<About />} />
          {/* <Route path="/pay" element={<Pay />} /> */}
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/delivery" element={<DeliveryPay />} />
          <Route path="/newOrder" element={<NewOrder />} />

          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

function NoMatch() {
  return (
    <div>
      <h2>Упс, такой страницы нет</h2>
      <p>
        <Link to="/">Перейти на главную</Link>
      </p>
    </div>
  );
}

export default App;
