import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { stores } from "../stores/rootStore";
import "./style.css";
import { getNeedSizePhoto } from "../utils/galleryUtils";
import { svgClose } from "../img/tools/close";
import rubsvg from "../img/rub.svg";

import {
  Input,
  InputWithValidate,
  SelectAddress,
} from "../components/inputs/inputs";
import { isValid, useField } from "../utils/formUtils";
import { toast } from "react-hot-toast";
import { TDaDataAddress, TOrderForCreate } from "../stores/types";
import { getBratskTimeNow } from "../utils/timeDate";
import { YMcreateOrder, YMremoveProductFromBasket } from "../utils/YMecomerce";
import { PopUpRules } from "./PopUpRules";
export const NewOrder = observer(() => {
  const { orderStore, helpUIStore, addressStore, settingStore } = stores;
  const {
    orderInfo,
    productsInOrder,
    setProductsInOrder,
    setOrderInfo,
    addOrder,
    resetOrder,
  } = orderStore;
  // VALIDATE
  const clientPhoneField = useField("");
  const [clientPhoneInp, setClientPhoneInp] = clientPhoneField;
  const receiverPhoneField = useField("");
  const [receiverPhoneInp, setReceiverPhoneInp] = receiverPhoneField;

  const [isShowSuccessInfo, setIsShowSuccessInfo] = useState(false);
  const [isAgreeWithRules, setIsAgreeWithRules] = useState(true);
  const [isShowPopUpRules, setIsShowPopUpRules] = useState(false);
  const [isOrderSending, setIsOrderSending] = useState(false);

  useEffect(() => {
    helpUIStore.setTitle("Новый заказ");
    helpUIStore.setHistory(["newOrder"]);
    settingStore.fetchSettings();
  }, []); // eslint-disable-line
  const {
    total_sum,
    client_fio,
    // client_phone,
    comment,
    is_need_note,
    note_message,
    is_need_photo,
    receiver_address,
    receiver_name,
    delivery_cost,
    // receiver_phone,
    time_delivery,
  } = orderInfo;

  // в составе Центрального района: Центральный, Порожский, Сосновый, Стениха, Новая Стениха, Чекановский.
  // в составе Падунского района: Падун, Энергетик, Южный Падун, Сосновый Бор, Бикей.
  // в составе Правобережного района: Гидростроитель, Осиновка, Сухой.
  const calculateDeliveryCost = (addr?: TDaDataAddress) => {
    if (!receiver_address) return 0;
    // const { settlement } = addressStore.address.data;
    const settlement = receiver_address
      .split(",")
      .filter((v) => v.includes("ж/р"))[0]
      ?.slice(5);
    if (!settlement) return 0;
    if (["Энергетик", "Центральный"].includes(settlement)) return 250;
    if (
      ["Гидростроитель", "Осиновка", "Южный Падун", "Падун"].includes(
        settlement
      )
    )
      return 350;
    if (["Сосновый"].includes(settlement)) return 400;
    if (["Чекановский"].includes(settlement)) return 450;
    if (["Стениха"].includes(settlement)) return 700;
    if (["Порожский", "Бикей"].includes(settlement)) return 800;
    if (["Сухой"].includes(settlement)) return 1100;
    return 0;
  };
  useEffect(() => {
    setValueInOrderInfo({
      delivery_cost: calculateDeliveryCost(addressStore.address),
    });
  }, [receiver_address]); // eslint-disable-line
  // const costDelivery = calculateDeliveryCost(addressStore.address);

  const productList = productsInOrder.map(
    ({ name, amount, photos, price, total_price, refOnProduct, idPD }, i) => {
      const onChangeAmount = (action: "inc" | "dec") => {
        if (amount === 1 && action === "dec") return;
        const newAmount = action === "inc" ? amount + 1 : amount - 1;
        const newProducts = [...productsInOrder];
        newProducts[i] = {
          ...newProducts[i],
          amount: newAmount,
          total_price: price * newAmount,
        };
        setProductsInOrder(newProducts);
      };
      return (
        <div key={idPD} className="newOrder_productItem">
          {/* <div className="pi_photo">
          </div> */}
          <Link
            title={`Открыть страницу с ${name}`}
            className="pi_photo"
            to={`/product/${refOnProduct}`}
          >
            <img src={getNeedSizePhoto(photos, "medium")[0]} alt={name} />
          </Link>
          <div className="newOrder_productItem_text">
            <Link
              title={`Открыть страницу с ${name}`}
              className="pi_name"
              to={`/product/${refOnProduct}`}
            >
              {name}
            </Link>
            <div className="pi_amount">
              <div
                title="Уменьшить кол-во товара в заказе"
                className="pi_amount_op"
                onClick={() => onChangeAmount("dec")}
              >
                -
              </div>
              <div title="Количество в заказе">{amount}</div>
              <div
                title="Увеличить кол-во товара в заказе"
                className="pi_amount_op"
                onClick={() => onChangeAmount("inc")}
              >
                +
              </div>
            </div>
            <div className="newOrder_pi_price_box">
              <div className="pi_price">{total_price}</div>
              <img src={rubsvg} alt="" />
            </div>
          </div>
          <div
            className="pi_del_product"
            title="Удалить из корзины"
            onClick={() => {
              setProductsInOrder([
                ...productsInOrder.filter((p) => p.idPD !== idPD),
              ]);
              YMremoveProductFromBasket({ name, id: idPD.toString() });
            }}
          >
            {svgClose}
          </div>
        </div>
      );
    }
  );
  // photo, name, amount, price, delete
  const setValueInOrderInfo = (obj: any) => {
    setOrderInfo({
      ...orderInfo,
      ...obj,
    });
  };
  const onSendOrder = async () => {
    if (!isValid([clientPhoneField]))
      return toast.error("Введите номер телефона", { duration: 2000 });
    if (!isAgreeWithRules)
      return toast.error("Необходимо согласиться с правилами", {
        duration: 2000,
      });

    setIsOrderSending(true);

    const orderForCreate: TOrderForCreate = {
      ...orderInfo,
      client_phone: clientPhoneInp.value,
      receiver_phone: receiverPhoneInp.value,
      time_create_order: getBratskTimeNow(),
      products: orderStore.productsInOrder,
    };
    const myPromise = addOrder(orderForCreate)
      .then((createdOrder) => {
        YMcreateOrder(
          orderStore.productsInOrder.map(
            ({ amount, price, idPD, prodName, PDName }) => ({
              id: idPD.toString(),
              name: prodName,
              price,
              quantity: amount,
              ...(PDName && {
                variant: PDName,
              }),
            })
          ),
          createdOrder.id.toString()
        );

        resetOrder();

        setIsShowSuccessInfo(true);
      })
      .finally(() => setIsOrderSending(false));

    toast.promise(myPromise, {
      loading: "Отправка заказа",
      success: "Спасибо за заказ",
      error: "Упс, что-то пошло не так",
    });
  };
  const clsNoteMes = is_need_note
    ? "pi_note_mess"
    : "pi_note_mess hide_pi_note_mes";
  if (isShowSuccessInfo)
    return (
      <div className="newOrder_orderInfo">
        <div>Спасибо, Ваш заказ принят!</div>
        <div>
          В ближайшее время с Вами свяжется менеджер для подтверждения заказа
        </div>
        {/* <div>
            {`№ заказа ${id}`}
          </div> */}
      </div>
    );
  if (!orderStore.productsInOrder.length)
    return <div className="newOrder_orderInfo">Нет товаров в корзине</div>;

  return (
    <div className="newOrder_wrapper">
      <div className="newOrder_form newOrder_nameCol">
        <Input
          id="client_name"
          autoFocus={true}
          label="Ваше имя"
          value={client_fio}
          setValue={(e) => {
            if (e.target.value.length > 200) return;
            setValueInOrderInfo({ client_fio: e.target.value });
          }}
          width={200}
        />
        <InputWithValidate
          id="client_phone"
          label="Ваш телефон*"
          setState={setClientPhoneInp}
          state={clientPhoneInp}
          typemod="numeric"
          forPhone="req"
          width={200}
        />

        <Input
          id="receiver_name"
          label="Имя получателя"
          value={receiver_name}
          setValue={(e) => {
            if (e.target.value.length > 200) return;
            setValueInOrderInfo({ receiver_name: e.target.value });
          }}
          width={200}
        />
        <InputWithValidate
          id="receiver_phone"
          label="Телефон получателя"
          setState={setReceiverPhoneInp}
          state={receiverPhoneInp}
          typemod="numeric"
          forPhone="opt"
          width={200}
        />

        <Input
          id="time_delivery"
          label="Дата и время доставки"
          type="dateTime"
          value={time_delivery}
          setValue={(e) =>
            setValueInOrderInfo({ time_delivery: e.target.value })
          }
          width={200}
        />
      </div>
      <div className="newOrder_form newOrder_addressCol">
        <SelectAddress
          id="receiver_address"
          label="Адрес доставки"
          type="textarea"
          // width={400}
          value={receiver_address}
          setValue={(addressString) => {
            if (addressString > 700) return;
            setValueInOrderInfo({ receiver_address: addressString });
            // calculateDeliveryCost();
          }}
        />
        <div
          className="checkboxControl"
          onClick={(e) =>
            setValueInOrderInfo({ is_need_photo: !is_need_photo })
          }
        >
          <input
            checked={!!is_need_photo}
            type="checkbox"
            name="checkbox"
            readOnly={true}
          />
          <span>Отправить фото готового букета</span>
        </div>
        <div
          className="checkboxControl"
          onClick={(e) => setValueInOrderInfo({ is_need_note: !is_need_note })}
        >
          <input
            type="checkbox"
            name="checkbox2"
            checked={is_need_note}
            readOnly={true}
            // value={is_need_note}
            // onClick={}
            // onChange={(e) =>
            //   setValueInOrderInfo({ is_need_note: !is_need_note })
            // }
          />
          <span>Прикрепить записку</span>
        </div>
        <div className={clsNoteMes}>
          <Input
            id="note_message"
            label="Текст записки"
            value={note_message}
            type="textarea"
            setValue={(e) => {
              if (e.target.value.length > 700) return;
              setValueInOrderInfo({ note_message: e.target.value });
            }}
          />
        </div>
        <Input
          id="comment"
          label="Комментарий"
          value={comment}
          type="textarea"
          setValue={(e) => {
            if (e.target.value.length > 700) return;
            setValueInOrderInfo({ comment: e.target.value });
          }}
        />
        <div
          className="checkboxControl"
          onClick={() => setIsAgreeWithRules(!isAgreeWithRules)}
        >
          <input
            checked={isAgreeWithRules}
            type="checkbox"
            name="checkbox"
            readOnly={true}
          />
          <span className="rules">
            Я согласен на{" "}
            <span
              title="Показать соглашение на обработку данных"
              onClick={(e) => {
                e.stopPropagation();
                setIsShowPopUpRules(true);
              }}
            >
              обработку персональных данных
            </span>
          </span>
        </div>
        <button
          disabled={isOrderSending || settingStore.settings.is_sales_close}
          onClick={onSendOrder}
          className={`newOrder_btn rose_btn ${
            isOrderSending && "newOrder_btn_disable"
          }`}
        >
          Отправить заказ
        </button>
      </div>

      <div className="newOrder_products">
        {productList}
        {delivery_cost ? (
          <>
            {orderStore.productsInOrder.length > 1 && (
              <div className="newOrder_products_price_box">
                <div className="products_total_price">
                  Стоимость товаров: {total_sum}
                </div>
                <img src={rubsvg} alt="руб" />
              </div>
            )}
            <div className="newOrder_products_price_box">
              <div className="products_total_price">
                Доставка: {delivery_cost}
              </div>
              <img src={rubsvg} alt="руб" />
            </div>
            <div className="newOrder_products_price_box">
              <div
                className="products_total_price"
                style={{ fontWeight: "bold" }}
              >
                Итого: {total_sum + delivery_cost}
              </div>
              <img src={rubsvg} alt="руб" />
            </div>
          </>
        ) : (
          <>
            <div className="newOrder_products_price_box">
              <div className="products_total_price">
                Итого(без учета доставки): {total_sum}
              </div>
              <img src={rubsvg} alt="" />
            </div>
            <div className="products_info">
              Полную стоимость сообщит менеджер после оформления заказа
            </div>
          </>
        )}
        {/* <div className="products_total_price">
            Итого(без учета доставки): {total_sum}
          </div>
          <img src={rubsvg} alt="" />
        </div> */}
      </div>
      {isShowPopUpRules && (
        <PopUpRules hidePopUp={() => setIsShowPopUpRules(false)} />
      )}
    </div>
  );
});
