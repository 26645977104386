export const renderLeftNav = (
  onClick: React.MouseEventHandler<HTMLElement>
) => {
  return (
    <div
      onClick={onClick}
      className="image-gallery-icon image-gallery-left-nav image_galery_navBtn "
      aria-label="Previous Slide"
    >
      <svg
        className="svgOnGallery"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Arrow / Caret_Circle_Left">
          <path
            id="Vector"
            d="M13 15L10 12L13 9M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};

export const renderRightNav = (
  onClick: React.MouseEventHandler<HTMLElement>
) => {
  return (
    <div
      onClick={onClick}
      className="image-gallery-icon image-gallery-right-nav image_galery_navBtn "
      aria-label="Previous Slide"
    >
      <svg
        className="svgOnGallery"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Arrow / Caret_Circle_Right">
          <path
            id="Vector"
            d="M11 9L14 12L11 15M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};
