export const svgCloseWhite = (
  <svg
    // width="800px"
    // height="800px"
    viewBox="0 0 800 800"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M329.289 329.289L470.711 470.711"
      stroke="#ffffff"
      fill="none "
      // strokeWidth="66.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M470.711 329.289L329.289 470.711"
      stroke="#ffffff"
      // strokeWidth="66.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M700 400C700 565.687 565.687 700 400 700C234.315 700 100 565.687 100 400C100 234.315 234.315 100 400 100C565.687 100 700 234.315 700 400Z"
      stroke="#ffffff"
      // strokeWidth="66.6667"
    />
    <path
      opacity="0.1"
      d="M700 400C700 565.687 565.687 700 400 700C234.315 700 100 565.687 100 400C100 234.315 234.315 100 400 100C565.687 100 700 234.315 700 400Z"
    />
  </svg>
);
