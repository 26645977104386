import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Input, InputWithValidate } from "../../components/inputs/inputs";
import { stores } from "../../stores/rootStore";

import "./style.css";
import { TOrderStatus } from "../../stores/types";
import { useField } from "../../utils/formUtils";
import { scrollHandler } from "../../utils/scrollHandler";
import { Link } from "react-router-dom";
// import { svgPencil } from "../../img/tools/pencil";
import { prepareDateTime } from "../../utils/timeDate";
import { PopUpOrder } from "./PopUpOrder";
import { TOrdersSortON } from "../../stores/OrderStore";
import { svgDown } from "../../img/down";

export const ORDERSTATUSES: TOrderStatus[] = [
  "Создан",
  "Подтвержден",
  "Оплачен",
  "Доставлен",
  "Отменен",
];
export const Orders = observer(() => {
  const { orderStore } = stores;
  const searchPhoneField = useField("");
  const [searchPhoneInp, setSearchPhoneInp] = searchPhoneField;

  useEffect(() => {
    // orderStore.fetchOrders();
    orderStore.setOptionsAndFetchAll({
      sortOn: "id_DESC",
      statuses: ["Создан", "Оплачен", "Подтвержден", "Доставлен"],
    });
    // productStore.setOptionsAndFetchAll({}, true);
    stores.helpUIStore.setTitle("Заказы");
  }, []); // eslint-disable-line
  const [idOrderForShow, setIdOrderForShow] = useState(0);
  const [isShowDDStatuses, setIsShowDDStatuses] = useState(false);

  const [popupMode, setPopupMode] = useState<"hide" | "view" | "edit">("hide");
  // useEffect(() => {
  //   document.addEventListener("scroll", scrollHandler);
  //   return () => document.removeEventListener("scroll", scrollHandler);
  // }, []);
  const onScroll = (e: any) => {
    if (
      orderStore.orders.length !== orderStore.count &&
      orderStore.offset < orderStore.count
    )
      scrollHandler(e, orderStore.getMoreOrders);
  };
  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, []); // eslint-disable-line
  useEffect(() => {
    if (searchPhoneInp.value.length !== 17) return;
    orderStore.setOptionsAndFetchAll({
      statuses: ORDERSTATUSES,
      clientPhone: searchPhoneInp.value,
      orderIdForFetch: "setUndefined",
    });
  }, [searchPhoneInp]); // eslint-disable-line

  const setPopup = (id: number, mode: "hide" | "view" | "edit") => {
    setIdOrderForShow(id);
    setPopupMode(mode);
  };
  const orderList = orderStore.orders.map(
    (
      {
        client_fio,
        client_phone,
        id,
        products,
        receiver_name,
        receiver_phone,
        status,
        time_create_order,
        time_delivery,
        total_sum,
      },
      i
    ) => {
      const dateCreate = prepareDateTime(time_create_order, "dd month");
      const timeDelivery = prepareDateTime(time_delivery, "dd month time");

      const bgrowstyle = i % 2 === 0 ? "evenRow" : "";
      // const tools = (
      //   <div className="admin_tools">
      //     <div
      //       className="svgTool svgEdit"
      //       title="Редактировать заказ"
      //       onClick={() => setPopup(id, "edit")}
      //     >
      //       {svgPencil}
      //     </div>
      //   </div>
      // );

      if (products?.length > 1) {
        const rowspan = products.length;
        const cellsProducts = products.reduce<JSX.Element[]>(
          (acc, product, i) => {
            if (i === 0) return [];
            acc.push(
              <tr key={product.id} className={bgrowstyle}>
                <td>
                  <Link
                    title={`Открыть страницу с ${product.name}`}
                    // className="pi_name"
                    to={`/product/${product.refOnProduct}`}
                  >
                    {product.name}
                  </Link>
                </td>
                <td>{product.amount}</td>
                <td>{product.price} ₽/шт</td>
                {/* <td id="prod_table_price">{PD.price}</td> */}
              </tr>
            );
            return acc;
          },
          []
        );
        return (
          <React.Fragment key={id}>
            <tr className={bgrowstyle}>
              <td
                rowSpan={rowspan}
                title={`Посмотреть заказ ${id + 2000}`}
                onClick={() => setPopup(id, "view")}
                className="orderLink"
              >
                {id + 2000}{" "}
              </td>
              <td rowSpan={rowspan}>{status}</td>
              <td rowSpan={rowspan}>{client_fio}</td>
              <td rowSpan={rowspan}>{client_phone}</td>
              <td rowSpan={rowspan}>{dateCreate}</td>
              <td rowSpan={rowspan}>{timeDelivery}</td>
              <td rowSpan={rowspan}>{receiver_name}</td>
              <td rowSpan={rowspan}>{receiver_phone}</td>
              <td rowSpan={rowspan}>
                <img
                  className="adminOrders_table_photo"
                  src={products[0]?.photos[0]}
                  alt={products[0].name}
                />
              </td>

              <td>
                <Link
                  title={`Открыть страницу с ${products[0].name}`}
                  to={`/product/${products[0].refOnProduct}`}
                >
                  {products[0].name}
                </Link>
              </td>
              <td>{products[0].amount}</td>
              <td>{products[0].price} ₽/шт</td>
              <td rowSpan={rowspan}>{total_sum}</td>
              {/* <td rowSpan={rowspan}>{tools}</td> */}
            </tr>
            {cellsProducts}
          </React.Fragment>
        );
      }
      return (
        <tr key={id} className={bgrowstyle}>
          <td
            onClick={() => setPopup(id, "view")}
            className="orderLink"
            title={`Посмотреть заказ ${id + 2000}`}
          >
            {id + 2000}
          </td>
          <td>{status}</td>
          <td>{client_fio}</td>
          <td>{client_phone}</td>
          <td>{dateCreate}</td>
          <td>{timeDelivery}</td>
          <td>{receiver_name}</td>
          <td>{receiver_phone}</td>
          <td>
            <img
              className="adminOrders_table_photo"
              src={products[0]?.photos[0]}
              alt={products[0].name}
            />
          </td>

          <td>
            <Link
              title={`Открыть страницу с ${products[0].name}`}
              // className="pi_name"
              to={`/product/${products[0].refOnProduct}`}
            >
              {products[0].name}
            </Link>
          </td>
          <td>{products[0].amount}</td>
          <td>{products[0].price} ₽/шт</td>
          <td>{total_sum}</td>
          {/* <td>{tools}</td> */}
        </tr>
      );
    }
  );

  const trTheadCells = [
    ["id", "№"],
    ["", "Статус"],
    ["", "Имя клиента"],
    ["", "Телефон клиента"],
    ["id", "Дата создания"],
    ["", "Время доставки"],
    ["", "Имя получателя"],
    ["", "Телефон Получателя"],
    ["", "Фото"],
    ["", "Название"],
    ["", "Кол-во"],
    ["", "Стоимость"],
    ["total_sum", "Итого, руб"],
  ].map(([val, title], i) => {
    if (!val) return <th key={i}>{title}</th>;
    let clazz = "access_table_sort";
    let setOrderBy = () => {
      const sortOn = (val + "_ASC") as TOrdersSortON;
      orderStore.setOptionsAndFetchAll({ sortOn });
    };
    if (orderStore.sortOn) {
      let [orderBy, direction] = orderStore.sortOn?.split("_");
      if (orderBy === "total") {
        orderBy = "total_sum";
        direction = orderStore.sortOn?.split("_")[2];
      }
      if (orderBy === val) {
        clazz += direction === "DESC" ? " table_sort_desc" : " table_sort_asc";
        setOrderBy = () => {
          const sortOn =
            direction === "DESC"
              ? ((val + "_ASC") as TOrdersSortON)
              : ((val + "_DESC") as TOrdersSortON);
          orderStore.setOptionsAndFetchAll({ sortOn });
        };
      }
    }
    // if (val === "name")
    //   return (
    //     <th key={i} onClick={setOrderBy} className={clazz} colSpan={2}>
    //       {title}
    //     </th>
    //   );
    return (
      <th key={i} onClick={setOrderBy} className={clazz}>
        {title}
      </th>
    );
  });
  const classDDBtnStatuses =
    orderStore.statuses?.length !== ORDERSTATUSES.length
      ? "rose_btn filterPanel_btn rose_btn_activate"
      : "rose_btn filterPanel_btn";
  const classDDPrice = isShowDDStatuses
    ? "rose_dropdown-options"
    : "rose_dropdown-options hide_dd";

  const DDoptionsStatuses = ORDERSTATUSES.map((status, i) => {
    const isStatusDisplay = orderStore.statuses?.includes(status);
    return (
      <div className="dd_option_aop" key={i}>
        <div
          className="checkboxControl "
          onClick={() => {
            let newStatuses = isStatusDisplay
              ? orderStore.statuses.filter((v) => v !== status)
              : [...orderStore.statuses, status];
            setIsShowDDStatuses(false);
            if (!newStatuses.length) return;
            setSearchPhoneInp({ ...searchPhoneInp, value: "" });
            orderStore.setOptionsAndFetchAll({
              statuses: newStatuses,
              clientPhone: "setUndefined",
              orderIdForFetch: "setUndefined",
            });
          }}
        >
          <input type="checkbox" checked={isStatusDisplay} readOnly={true} />
          <span>{status}</span>
        </div>
      </div>
    );
  });

  const filterStatusName =
    ORDERSTATUSES.length === orderStore.statuses.length
      ? "Все статусы"
      : `Все статусы кроме: ${ORDERSTATUSES.filter(
          (stat) => !orderStore.statuses.includes(stat)
        ).join(", ")}`;
  return (
    <div className="adminOrders_wrapper">
      <div className="adminOrders_panel_up">
        <div className="rose_dropdown">
          <div
            className={classDDBtnStatuses}
            onClick={() => setIsShowDDStatuses((v) => !v)}
          >
            <span>{filterStatusName}</span>
            <div className="filterPanel_down">{svgDown}</div>
          </div>
          <div className={classDDPrice}>{DDoptionsStatuses}</div>
        </div>
        <Input
          id="searchID"
          label="Поиск по № заказа"
          width={200}
          value={orderStore.orderIdForFetch ? orderStore.orderIdForFetch : ""}
          setValue={(e) => {
            if (isNaN(+e.target.value)) return;

            setSearchPhoneInp({ ...searchPhoneInp, value: "" });
            orderStore.setOptionsAndFetchAll({
              statuses: ORDERSTATUSES,
              clientPhone: "setUndefined",
              orderIdForFetch: +e.target.value
                ? e.target.value
                : "setUndefined",
            });
          }}
        />

        <InputWithValidate
          id="receiver_phone"
          label="Поиск по телефону"
          setState={setSearchPhoneInp}
          state={searchPhoneInp}
          typemod="numeric"
          forPhone="opt"
          width={200}
        />
      </div>

      <div className="adminOrders_productList">
        <table className="rose_table adminOrders_table">
          <thead>
            <tr>
              {trTheadCells}
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>{orderList}</tbody>
        </table>
      </div>
      {popupMode !== "hide" && (
        <PopUpOrder
          hidePopUp={() => setPopup(0, "hide")}
          idOrder={idOrderForShow}
          popUpMode={popupMode}
        />
      )}
    </div>
  );
});
