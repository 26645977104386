// import { stores } from "../stores/rootStore";
// export const scrollHandler = (e: any) => {
//   if (stores.productStore.products.length === stores.productStore.count) return;
//   if (
//     e.target.documentElement.scrollHeight -
//       (e.target.documentElement.scrollTop + window.innerHeight) <
//     250
//   ) {
//     stores.productStore.getMoreProducts();
//   }
// };
export const scrollHandler = (e: any, fun: () => void) => {
  if (
    e.target.documentElement.scrollHeight -
      (e.target.documentElement.scrollTop + window.innerHeight) <
    250
  ) {
    fun();
  }
};
