import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ProductList } from "./productList/ProductList";
import "./style.css";
export const Catalog = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/catalog") {
      navigate("all");
    }
    // }
  }, [location.pathname]); // eslint-disable-line
  return <ProductList isSearchPage={location.pathname === "/search"} />;
});
