export const searchSVG = (
  <svg
    // width="19"
    // height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeWidth="1.2"
      d="M10.1 13.6001C13.6898 13.6001 16.6 10.69 16.6 7.1001C16.6 3.51025 13.6898 0.600098 10.1 0.600098C6.51013 0.600098 3.59998 3.51025 3.59998 7.1001C3.59998 10.69 6.51013 13.6001 10.1 13.6001Z"
      stroke="#fff"
      strokeMiterlimit="10"
    />
    <path
      strokeWidth="1.2"
      d="M2.59998 16.9999L6.29998 12.3999"
      stroke="#fff"
      strokeMiterlimit="10"
    />
  </svg>
);
