import { NavLink } from "react-router-dom";
import vk from "../../staticPages/img/VK.svg";
import whats from "../../staticPages/img/WhatsApp.svg";
// import viber from "../../staticPages/img/Viber.svg";

import "./style.css";

export const Footer = () => {
  return (
    <footer className="app_footer">
      <div className="footer_links">
        <nav className="footer_nav">
          <NavLink to="/catalog">Каталог</NavLink>
          <NavLink to="/about">Контакты</NavLink>
          <NavLink to="/reviews">Отзывы</NavLink>
          <NavLink to="/delivery">Доставка</NavLink>
          <NavLink to="/delivery">Оплата</NavLink>
        </nav>
        <div className="footer_socNetwork">
          <a
            title="Мы Вконтакте"
            href="https://vk.com/roza_luxury"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={vk} alt="Вконтакте" />
          </a>
          <a
            title="Мы в Whatsapp"
            href="https://api.whatsapp.com/send/?phone=79643570388&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21%20%D0%9F%D0%BE%D0%B4%D1%81%D0%BA%D0%B0%D0%B6%D0%B8%D1%82%D0%B5&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whats} alt="Whatsapp" />
          </a>
          {/* <a title="Мы в Viber" href="https://vk.com/roza_luxury" target="_blank">
          <img src={viber} alt="viber" />
        </a> */}
        </div>
      </div>
      <div className="footer_bottom">
        ООО "Роза Люкс", Братск {new Date().getFullYear()}г
      </div>
      {/* , ОГРН 1153850007266 */}
    </footer>
  );
};
