type ProductYM = {
  id: string;
  name: string;
  price?: number;
  brand?: string;
  category?: string;
  variant?: string;
  quantity?: number;
};
// @ts-ignore
const dataForYM = window.dataForYM;
export const YMopenProductCard = (product: ProductYM) => {
  if (process.env.REACT_APP_ENV_NOW !== "prod") return;

  dataForYM.push({
    ecommerce: {
      currencyCode: "RUB",
      detail: {
        products: [product],
      },
    },
  });
};
export const YMaddProductToBasket = (product: ProductYM) => {
  if (process.env.REACT_APP_ENV_NOW !== "prod") return;

  dataForYM.push({
    ecommerce: {
      currencyCode: "RUB",
      add: {
        products: [product],
      },
    },
  });
};
export const YMremoveProductFromBasket = (product: ProductYM) => {
  if (process.env.REACT_APP_ENV_NOW !== "prod") return;

  dataForYM.push({
    ecommerce: {
      currencyCode: "RUB",
      remove: {
        products: [product],
      },
    },
  });
};

export const YMcreateOrder = (products: ProductYM[], orderId: string) => {
  if (process.env.REACT_APP_ENV_NOW !== "prod") return;

  dataForYM.push({
    ecommerce: {
      currencyCode: "RUB",
      purchase: {
        actionField: {
          id: orderId,
        },
        products,
      },
    },
  });
};
