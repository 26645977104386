const MONTHS = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря",
];

/**
 * format: 2023-04-15T13:07
 */
export const getBratskTimeNow = () => {
  const nowTime = new Date();
  const localTimeNow = new Date(
    nowTime.getFullYear(),
    nowTime.getMonth(),
    nowTime.getDate(),
    nowTime.getHours() + 8,
    nowTime.getMinutes()
  );
  return localTimeNow.toISOString().slice(0, 16);
};

export const prepareDateTime = (
  datestr: string,
  format: "dd month" | "dd month time" | "dd month year time" | "dd month year"
) => {
  if (!datestr || datestr?.length < 5) return "";
  const date = new Date(datestr);
  let res = date.getDate() + " " + MONTHS[date.getMonth()];
  if (format === "dd month year time")
    res += " " + date.getFullYear() + " " + datestr.split("T")[1];
  if (format === "dd month year") res += " " + date.getFullYear();
  if (format === "dd month time") res += " " + datestr.split("T")[1];
  return res;
};
