// export const svgDown = (
//   <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <g id="Arrow / Caret_Circle_Down">
//       <path
//         id="Vector"
//         d="M15 11L12 14L9 11M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12Z"
//         strokeWidth="2"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </g>
//   </svg>
// );

export const svgDown = (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 8L12 16L2 8"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
