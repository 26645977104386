export const svgPencil = (
  <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.556 14.9094L13.0997 26.4114C12.7244 26.7882 12.2145 27 11.6827 27H10.0038C9.44943 27 9 26.5466 9 25.9922V24.2987C9 23.7696 9.20962 23.2621 9.58297 22.8873L21.0647 11.3597C21.0742 11.4062 21.0969 11.4505 21.1329 11.4865L24.5506 14.9043C24.5524 14.906 24.5542 14.9077 24.556 14.9094Z"
      opacity="0.1"
      fill="#323232"
    />
    <path
      d="M34 18C34 26.8366 26.8366 34 18 34C9.16345 34 2 26.8366 2 18C2 9.16345 9.16345 2 18 2C26.8366 2 34 9.16345 34 18Z"
      stroke="#323232"
      strokeWidth="3"
    />
    <path
      d="M26.0651 13.3942L13.0997 26.4114C12.7244 26.7882 12.2145 27 11.6827 27H10.0038C9.44943 27 9 26.5466 9 25.9922V24.2987C9 23.7696 9.20962 23.2621 9.58297 22.8873L22.5517 9.86681C25.5632 7.34721 28.5747 10.8746 26.0651 13.3942Z"
      fill="none"
      stroke="#323232"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21.3096 11.3098L24.7273 14.7276Z" fill="none" />
    <path
      d="M21.3096 11.3098L24.7273 14.7276"
      stroke="#323232"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
