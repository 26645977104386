import { action, makeObservable } from "mobx";
import { HTTPservice } from "../services/HTTPservice";
export class PhotoStore {
  constructor() {
    makeObservable(this, {
      uploadPhotos: action,
    });
  }

  public async uploadPhotos(photoArr: File[]) {
    if (!photoArr.length) return [];
    const body = new FormData();
    photoArr.forEach((photo) => {
      body.append("photosOrPhoto", photo);
    });

    const res = await HTTPservice.post<string[]>("photos", body, true);
    // if (res === 401) return;

    return res.map((v) => `${process.env.REACT_APP_BACK_ENV}photos/big/${v}`);
  }
}
