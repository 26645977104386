import { ReactImageGalleryItem } from "react-image-gallery";

export const getNeedSizePhoto = (
  arrSrc: string[],
  to: "big" | "medium"
): string[] => {
  const regex = /\/photos\/big\/|\/photos\/medium\//g;
  return arrSrc.map((src) => src.replace(regex, `/photos/${to}/`));
};

export const getPhotoForGallery = (
  arrSrc: string[] = [],
  needThumbnail: boolean = false,
  title: string = ""
): ReactImageGalleryItem[] => {
  const arrBigSrc = getNeedSizePhoto(arrSrc, "big");
  const arrMediumSrc = getNeedSizePhoto(arrSrc, "medium");

  return needThumbnail
    ? arrSrc.map((src, i) => ({
        original: arrBigSrc[i],
        thumbnail: arrMediumSrc[i],
        originalAlt: title,
        originalTitle: title,
      }))
    : arrSrc.map((src, i) => ({
        original: arrMediumSrc[i],
        originalClass: "borRad",
        originalAlt: title,
        originalTitle: title,
      }));
};

export const getPhotoForReview = (
  arrSrc: { big: string; small: string }[] = [],
  forFull = false,
  title: string = "Фото клиента",
  needThumbnail: boolean = false
): ReactImageGalleryItem[] => {
  return arrSrc.map(({ big, small }) => ({
    original: forFull ? big : small,
    fullscreen: big,
    originalClass: "borRad",
    originalAlt: title,
    originalTitle: title,
  }));
};
