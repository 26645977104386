import { action, makeObservable, observable } from "mobx";
export class HelpUIStore {
  constructor() {
    makeObservable(this, {
      title: observable,
      history: observable,
      setTitle: action,
      setHistory: action,
    });
  }

  history: string[] = [];
  title: string = "";
  public setTitle = (title: string) => {
    this.title = title;
  };
  public setHistory(newHistory: string[]) {
    this.history = newHistory;
  }
}
