import { CategoryStore } from "./CategoryStore";
import { ProductStore } from "./ProductStore";
import { PhotoStore } from "./PhotoStore";
import { UserStore } from "./UserStore";
import { ComponentStore } from "./ComponentStore";
import { HelpUIStore } from "./HelpUIStore";
import { OrderStore } from "./OrderStore";
import { AddressStore } from "./AddressStore";
import { ReviewStore } from "./ReviewStore";
import { SettingStore } from "./SettingStore";

type TStores = {
  orderStore: OrderStore;
  productStore: ProductStore;
  categoryStore: CategoryStore;
  photoStore: PhotoStore;
  userStore: UserStore;
  componentStore: ComponentStore;
  addressStore: AddressStore;
  helpUIStore: HelpUIStore;
  reviewStore: ReviewStore;
  settingStore: SettingStore;
};

export let stores: TStores;

class RootStore {
  public stores: {
    orderStore: any;
    productStore: any;
    categoryStore: any;
    photoStore: any;
    userStore: any;
    componentStore: any;
    addressStore: any;
    helpUIStore: any;
    reviewStore: any;
    settingStore: any;
  } = {
    orderStore: {},
    productStore: {},
    categoryStore: {},
    photoStore: {},
    userStore: {},
    componentStore: {},
    addressStore: {},
    helpUIStore: {},
    reviewStore: {},
    settingStore: {},
  };

  constructor() {
    this.initStores();

    stores = this.stores;
  }
  public initStores = () => {
    this.stores.orderStore = new OrderStore();
    this.stores.productStore = new ProductStore();
    this.stores.categoryStore = new CategoryStore();
    this.stores.photoStore = new PhotoStore();
    this.stores.userStore = new UserStore();
    this.stores.componentStore = new ComponentStore();
    this.stores.addressStore = new AddressStore();
    this.stores.helpUIStore = new HelpUIStore();
    this.stores.reviewStore = new ReviewStore();
    this.stores.settingStore = new SettingStore();
  };
}

new RootStore();
