import { action, makeObservable, observable, runInAction } from "mobx";
import { HTTPservice } from "../services/HTTPservice";
import {
  TOrder,
  TOrderForCreate,
  TOrderStatus,
  TProductInOrder,
  TProductWithComponents,
} from "./types";
import { ORDERSTATUSES } from "../admin/orders/Orders";
const empryOrderInfo: Omit<TOrder, "products" | "id"> = {
  client_fio: "",
  client_phone: "",
  receiver_phone: "",
  receiver_name: "",
  receiver_address: "",
  time_delivery: "",
  is_need_photo: false,
  is_need_note: false,
  note_message: "",
  comment: "",
  total_sum: 0,
  delivery_cost: 0,
  status: "Создан",
  time_create_order: "",
  time_close_order: "",
};
export type TOrdersSortON =
  | "id_DESC"
  | "id_ASC"
  | "total_sum_ASC"
  | "total_sum_DESC"
  | "no";

type TFetchOptions = {
  clientPhone?: string;
  statuses?: TOrderStatus[];
  orderIdForFetch?: string;
  sortOn?: TOrdersSortON;
  offset?: number;
};

export class OrderStore {
  constructor() {
    makeObservable(this, {
      orders: observable,
      productsInOrder: observable,
      orderInfo: observable,
      setProductsInOrder: action,
      setOrderInfo: action,
      fetchOrders: action,
      sortOn: observable,
      orderIdForFetch: observable,
      clientPhone: observable,
      statuses: observable,
      offset: observable,
    });
    this.productsFromStorageToBasket();
  }
  orderIdForFetch: string | undefined;
  clientPhone: string | undefined;
  statuses: TOrderStatus[] = [];
  sortOn: TOrdersSortON | undefined;
  count: number = 0;
  offset: number = 0;
  private limit: number = 10;

  public productsFromStorageToBasket = async () => {
    const productsForBasket: TProductInOrder[] = [];

    for (let pdFromStorage of this.storage.productsInBasket) {
      const { ProductDefinations, is_archive } =
        await HTTPservice.get<TProductWithComponents>(
          `products/${pdFromStorage.idProduct}`
        );

      if (is_archive) continue;

      const freshPDinfo = ProductDefinations?.find(
        (pd) => pd.id === pdFromStorage.idPD
      );
      if (!freshPDinfo) continue;

      productsForBasket.push({
        ...pdFromStorage,
        price: freshPDinfo.price,
        total_price: freshPDinfo.price * pdFromStorage.amount,
      });
    }

    this.productsInOrder = productsForBasket;
    this.orderInfo = {
      ...this.orderInfo,
      total_sum: productsForBasket.reduce((acc, v) => acc + v.total_price, 0),
    };
  };

  storage = {
    get productsInBasket(): TProductInOrder[] {
      return JSON.parse(localStorage.getItem("productsInBasket") || "[]");
    },
    set productsInBasket(products: TProductInOrder[]) {
      localStorage.setItem("productsInBasket", JSON.stringify(products));
    },
  };

  orders: TOrder[] = [];
  productsInOrder: TProductInOrder[] = [];
  orderInfo: Omit<TOrder, "products" | "id"> = {
    ...empryOrderInfo,
    total_sum: this.productsInOrder.reduce((acc, v) => acc + v.total_price, 0),
  };

  public getMoreOrders = () => {
    runInAction(() => (this.offset += this.limit));
    this.fetchOrders({
      orderIdForFetch: this.orderIdForFetch,
      sortOn: this.sortOn,
      offset: this.offset,
      clientPhone: this.clientPhone,
      statuses: this.statuses,
    });
  };
  setOptionsAndFetchAll({
    clientPhone,
    orderIdForFetch,
    sortOn,
    statuses,
  }: TFetchOptions) {
    // back ищет только по одному из параметров (clientPhone, orderIdForFetch,statuses) сейчас, поэтому когда передаем один, другие убираем при вызове
    if (clientPhone || sortOn || orderIdForFetch || statuses)
      runInAction(() => (this.offset = 0));
    if (sortOn) runInAction(() => (this.sortOn = sortOn));
    if (clientPhone !== "setUndefined" && clientPhone)
      runInAction(() => (this.clientPhone = clientPhone));
    if (clientPhone === "setUndefined")
      runInAction(() => (this.clientPhone = undefined));

    if (orderIdForFetch !== "setUndefined" && orderIdForFetch)
      runInAction(() => (this.orderIdForFetch = orderIdForFetch));
    if (orderIdForFetch === "setUndefined")
      runInAction(() => (this.orderIdForFetch = undefined));

    if (statuses) runInAction(() => (this.statuses = statuses));
    // if (!statuses?.length && statuses !== undefined)
    // runInAction(() => (this.statuses = ORDERSTATUSES));
    this.fetchOrders({
      orderIdForFetch: this.orderIdForFetch,
      sortOn: this.sortOn,
      offset: this.offset,
      clientPhone: this.clientPhone,
      statuses: this.statuses,
    });
  }

  public async fetchOrders(options?: TFetchOptions) {
    const query = new URLSearchParams();
    if (options?.orderIdForFetch) {
      const prepareId = +options.orderIdForFetch - 2000;
      if (prepareId < 0) return;
      query.append("orderId", prepareId.toString());
    } else if (options?.clientPhone) {
      query.append("clientPhone", options.clientPhone.slice(1));
    } else if (options?.statuses?.length) {
      if (options.statuses.length !== ORDERSTATUSES.length)
        // когда все статусы выбраны, то фильтрацию по статусам можно не слать
        query.append("statuses", JSON.stringify(options.statuses));
    }
    if (options?.sortOn) {
      let [orderBy, dir] = options?.sortOn.split("_");
      if (orderBy === "total") {
        orderBy = "total_sum";
        dir = options.sortOn?.split("_")[2];
      }
      query.append("orderby", orderBy);
      query.append("direction", dir);
    }
    if (options?.offset !== undefined) {
      query.append("offset", options.offset.toString());
      query.append("limit", this.limit.toString());
    }
    const queryString = query.toString();

    const res = await HTTPservice.get<{ items: TOrder[]; count: number }>(
      `orders?${queryString}`,
      true
    );
    if (this.offset) {
      runInAction(() => {
        this.orders = [...this.orders, ...res.items];
        this.count = res.count;
      });
    } else {
      runInAction(() => {
        this.orders = res.items;
        this.count = res.count;
      });
    }

    return res;
  }
  public async addOrder(order: TOrderForCreate) {
    const res = await HTTPservice.post<TOrder>("orders", order);
    // this.fetchProducts();
    return res;
  }
  public resetOrder = () => {
    this.setProductsInOrder([]);
    runInAction(() => {
      this.orderInfo = empryOrderInfo;
    });
  };

  public setOrderInfo = (newOrderInfo: Omit<TOrder, "products" | "id">) => {
    this.orderInfo = newOrderInfo;
  };
  public setProductsInOrder = (products: TProductInOrder[]) => {
    this.productsInOrder = products;
    this.storage.productsInBasket = products;
    this.orderInfo = {
      ...this.orderInfo,
      total_sum: products.reduce((acc, v) => acc + v.total_price, 0),
    };
  };

  public async updateOrder(body: TOrder) {
    const res = await HTTPservice.put<TOrder>(`orders/${body.id}`, body, true);
    this.setOptionsAndFetchAll({});
    return res;
  }
}

// public async deleteCategory(id: number) {
//   const res = await HTTPservice.delete(`categories/${id}`, true);
//   if (res === 401) throw new Error("not auth");
//   else {
//     this.fetchOrders();
//     return res;
//   }
// }
