import { useEffect } from "react";
import { stores } from "../stores/rootStore";
import "./about.css";
import maker from "./img/maker2Ready.png";
import vk from "./img/VK.svg";
import whats from "./img/WhatsApp.svg";
import viber from "./img/Viber.svg";

export const About = () => {
  useEffect(() => {
    stores.helpUIStore.setTitle("о нас");
    stores.helpUIStore.setHistory(["about"]);
    //@ts-ignore
    fetch("https://maps.api.2gis.ru/2.0/loader.js?pkg=full")
      .then((v) => v.text())
      .then((v) => {
        Function(v)(); // eslint-disable-line
        //@ts-ignore
        return window.DG.then(() => true);
      })
      .then(() => {
        //@ts-ignore
        DG.then(function () {
          //@ts-ignore
          const map = DG.map("map", {
            // center: [56.23, 101.69],
            center: [56.235, 101.69],
            zoom: 11,
          });
          //@ts-ignore
          var myIcon = DG.icon({
            iconUrl: maker,
            iconRetinaUrl: maker,
            iconSize: [25, 40],
            iconAnchor: [12, 40],
            // popupAnchor: [-3, -76],
            // shadowUrl: "my-icon-shadow.png",
            // shadowRetinaUrl: "my-icon-shadow@2x.png",
            // shadowSize: [68, 95],
            // shadowAnchor: [22, 94],
          });

          // DG.marker([50.505, 30.57], {icon: myIcon}).addTo(map);
          //@ts-ignore
          DG.marker([56.173499, 101.614839], { icon: myIcon })
            .addTo(map)
            .bindPopup("Роза люкс, ул.Крупской, 27");
          //@ts-ignore
          DG.marker([56.163401, 101.627263], { icon: myIcon })
            .addTo(map)
            .bindPopup("Роза люкс, ул.Мира, 61");
          //@ts-ignore
          DG.marker([56.151236, 101.636417], { icon: myIcon })
            .addTo(map)
            .bindPopup("Роза люкс, ул.​Советская, 1");
          //@ts-ignore
          DG.marker([56.306614, 101.757687], { icon: myIcon })
            .addTo(map)
            .bindPopup("Роза люкс, ул.​​Погодаева, 16");
        });
      });
  }, []);
  return (
    <div className="about_wrapper">
      <div className="about_info_head"> Дарим радость уже 12 лет 🌼</div>
      <div className="about_info_head">Мы находимся:</div>
      <div id="map"></div>
      <div className="about_info_head">Наши контакты:</div>
      <div className="about_info">
        <div className="about_item">
          <img src={maker} alt="Маркер" />
          <div>
            <div>ул. Мира 61 </div>
            <div>🕚 Круглосуточно</div>
            <div>📞+7(3953)380-388</div>
          </div>
        </div>
        <div className="about_item">
          <img src={maker} alt="Маркер" />
          <div>
            <div>ул. Советская 1</div>
            <div>🕚 вс-чт 9.00 - 21.00</div>
            <div>🕚 пт, сб 9.00 - 23.00</div>
            <div>📞+7(3953)45-35-10</div>
          </div>
        </div>
        <div className="about_item">
          <img src={maker} alt="Маркер" />
          <div>
            <div>ул. Крупской 27</div>
            <div>🕚 вс-чт 9.00 - 21.00</div>
            <div>🕚 пт, сб 9.00 - 23.00</div>
            <div>📞+7(3953)28-50-56</div>
          </div>
        </div>
        <div className="about_item">
          <img src={maker} alt="Маркер" />
          <div>
            <div>ул. Погодаева 16</div>
            <div>🕚 вс-чт 8.00 - 20.00</div>
            <div>🕚 пт, сб 8.00 - 22.00</div>
            <div>📞+7(3953)388-188</div>
          </div>
        </div>
      </div>
      <div className="about_contacts">
        <a
          href="https://vk.com/roza_luxury"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="about_contact_item">
            <img src={vk} alt="Вконтакте" />
            <span>Мы Вконтакте</span>
          </div>
        </a>
        <div className="about_contact_item">
          <img src={whats} alt="Whatsapp" />
          <img src={viber} alt="viber" />
          <span>+7 (964) 357-03-88</span>
        </div>
      </div>
    </div>
  );
};
